import React from "react";

export default function BeconChain() {
  return (
    <>
      <div
        className="seller-wrapper py-5 dark-bg"
        style={{ backgroundColor: "#000000" }}
      >
        <div className="container-wide py-lg-5">
          <div className="row">
            <div className="col-12 d-flex">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                The Network1 Chain
              </h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black dark-bg3 rounded-10 gap-2">
                <div className="d-flex flex-column">
                  <h1 className="rajdhani-600 text-gray-900 dark-text h2 d-flex gap-1 mb-0 mt-1">
                    Total NET1 Staked
                  </h1>
                  <br />
                  <span className=" rajdhani-500 fs-2 d-flex gap-1 text-success">
                    <img
                      src="assets/images/img-one/n1_58.png"
                      alt="img"
                      className="w-40 my-auto  "
                    />
                    0
                  </span>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2">
                <div className="d-flex flex-column">
                  <h1 className="rajdhani-600 text-gray-900 dark-text h2 d-flex  gap-1 mb-0 mt-1">
                    Total validators
                  </h1>
                  <br />
                  <span className=" rajdhani-500 fs-2 d-flex gap-1 text-success">
                    0
                  </span>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2">
                <div className="d-flex flex-column">
                  <h1 className="rajdhani-600 text-gray-900 dark-text h2 d-flex  gap-1 mb-0 mt-1">
                    Current APR
                  </h1>
                  <br />
                  <span className=" rajdhani-500 fs-2 d-flex gap-1 text-success">
                    0.0%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            {/* <div className='col-12 text-center'>
                          <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase">Join as creater</a> */}
            <div className="fugu--btn-wrap  fugu--hero-btn text-center">
              <a
                className="fugu--btn bg-gradient"
                href=""
                style={{ color: "white" }}
              >
                Join as creater
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
