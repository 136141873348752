import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import { useState } from "react";

export default function Withdrawals() {
  const [showtab, setShowTab] = useState(0);

  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        // style={{
        //   backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        // }}
        style={{
          background: "black",
        }}
        //     "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)",
        // }}
      >
        <Header />
        <div className="container mt-xl-5 mt-lg-5">
          <br />
          <br />
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Staking withdrawals
          </h2>
          <p className="h6 pt-4">
            Unlike other blockchain, Network1 Chain allows staking withdrawal to
            validators. This feature enables rewards to be automatically
            withdrawn to the execution layer, and also provides a way for exited
            validators to unlock their entire balance with no gas required.
          </p>
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-4">
            <a href="#" className="text-white">
              Enabling withdrawals
            </a>
            <hr />
          </h2>
          <p className="h6">
            For your validator to be capable of withdrawals of any kind, a
            withdrawal address must be provided and registered to your account
            on the Network1 Chain. This should be an address you control, and
            cannot be changed once set.
          </p>
          {/* button */}

          <div className="col-10 mt-2  portfolio-tab ">
            <ul className="d-flex  nft-wrapper m-3  h6">
              <li
                className={
                  showtab === 0 ? "p-0 filter m-2 active" : "p-0 filter m-2"
                }
                data-filter=".Download CLI app"
                onClick={() => setShowTab(0)}
              >
                <span style={{ color: "white" }}>Current validators</span>
              </li>
              <li
                className={
                  showtab === 1 ? "p-0 filter m-2 active" : "p-0 filter m-2"
                }
                data-filter=".game"
                onClick={() => setShowTab(1)}
              >
                <span style={{ color: "white" }}>
                  New validators(not yet deposited)
                </span>
              </li>
            </ul>
          </div>

          <div className="row justify-content-center mt-3">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-5 pt-0 mt-3">
                <div className={showtab === 0 ? "" : "d-none"}>
                  <h5 className="h5 pt-4 rajdhani-600">Current validators</h5>
                  <p className="h6">
                    Depending how you set up your initial deposit, your account
                    may or may not be ready for withdrawals already:
                  </p>
                  <div className="mx-5">
                    <p className="h6 mt-4">
                      <i
                        className="bi bi-circle-fill "
                        style={{ fontSize: "8px" }}
                      />{" "}
                      <span className="h6 rajdhani-600 mx-1">
                        Staking Deposit CLI :
                      </span>{" "}
                      if you used the “eth1 withdrawal address”
                      (--eth1_withdrawal_address) flag when generating your
                      keys, you're good to go
                    </p>
                    <p className="h6 ">
                      <i
                        className="bi bi-circle-fill "
                        style={{ fontSize: "8px" }}
                      />{" "}
                      <span className="h6 rajdhani-600 mx-1">
                        Wagyu Key Gen GUI :
                      </span>{" "}
                      if you provided a withdrawal address when generating your
                      keys, you're good to go
                    </p>
                  </div>
                  <p className="h6 mt-4">
                    Enter your validator index here to check if you're account
                    is ready for withdrawals or not:
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-xl-4 col-md-6">
                      <input
                        type="number"
                        className="p-2  mt-2"
                        placeholder="Validator"
                      />
                    </div>&nbsp;
                    {/* <div className="col-xl-4col-md-5 col-sm-12">
                    <a
                        href="#"
                        className="btn btn-lg text-gray-900 mt-1 rajdhani-700 rounded-0 bg-current border-current border-2 px-lg-5 font-sm text-uppercase"
                      >
                        Join as creater
                      </a>
                    </div> */}
                    <div className="col-xl-4 col-md-5 ">
                      <a
                        className="fugu--btn bg-gradient"
                        href="#"
                        style={{ color: "white" }}
                      >
                        Join as creater
                      </a>
                    </div>
                  </div>
                </div>
                <div className={showtab === 1 ? "" : "d-none"}>
                  <h5 className="h5 pt-4 rajdhani-600">
                    New validators (not yet deposited)
                  </h5>
                  <p className="h6">
                    If you’re preparing to make a deposit to activate a new
                    validator, you can (and should) provide a withdrawal address
                    with your initial deposit. This is done at time of key
                    generation, and is then included in your deposit data json
                    file deposit_data-{"<timestamp>"}.json which is submitted
                    with your 10,000 NET1 deposit transaction.
                  </p>
                  <p className="h6">
                    Depending which method you use to generate your keys:
                  </p>

                  <ul className="d-flex mx-2">
                    <li>
                      <i
                        className="bi bi-circle-fill mx-2 "
                        style={{ fontSize: "8px" }}
                      />{" "}
                    </li>
                    <li>
                      <p className="h6">
                        <span className="rajdhani-600 mx-1">
                          Staking Deposit CLI :{" "}
                        </span>
                        This is done by using the “eth1 withdrawal address”
                        (--eth1_withdrawal_address) flag when generating your
                        keys with the Staking Deposit CLI.
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex mx-2">
                    <li>
                      <i
                        className="bi bi-circle-fill mx-2 "
                        style={{ fontSize: "8px" }}
                      />{" "}
                    </li>
                    <li>
                      <p className="h6">
                        <span className="rajdhani-600 mx-1">
                          Wagyu Key Gen GUI :{" "}
                        </span>{" "}
                        This software is being updated to require users to
                        provide a withdrawal address during key generation. In
                        the meantime, you may have to check “Advanced” during
                        setup to enter a withdrawal address.By providing this
                        flag, your withdrawal credentials will contain the 0x01
                        prefix and your withdrawal address, signaling that your
                        account is ready for withdrawals
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row justify-content-center mt-3">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-5 pt-0 mt-3">
                <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-5 pb-4">
                  <a href="#" className="text-white">
                    How to update validator keys
                  </a>
                </h2>
                <p className="h6 mb-4">
                  If your validator account still has BLS withdrawal credentials
                  (0x00), a one-time message must be broadcast signaling which
                  execution layer account should be used for all withdrawals.
                </p>
                <p className="h6">
                  {" "}
                  <i className="bi bi-exclamation-triangle-fill mx-2 text-yellow" />{" "}
                  = "Type 0" = BLS keys = Old withdrawal credentials: Not
                  eligible for withdrawals
                </p>
                <p className="h6">
                  {" "}
                  <i className="bi bi-check-circle-fill text-success mx-2 " />=
                  "Type 1" = Execution keys = New withdrawal credentials:
                  Withdrawals enabled
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-5 pt-0 mt-3">
                <h2 className="h5 text-white rajdhani-600 lh-1 m-0 pt-5 pb-4">
                  Tools available for generating key change message
                </h2>
                <ul className="d-flex mx-2">
                  <li>
                    <i
                      className="bi bi-circle-fill mx-2 "
                      style={{ fontSize: "8px" }}
                    />{" "}
                  </li>
                  <li>
                    <p className="h6">
                      <span className="rajdhani-600 mx-1">
                        Staking Deposit CLI -{" "}
                        <a href="#" className="text-blue">
                          Launchpad walkthrough tutorial{" "}
                          <i className="bi bi-arrow-up-right m-1" />
                        </a>
                      </span>
                    </p>
                  </li>
                </ul>
                <ul className="d-flex mx-2">
                  <li>
                    <i
                      className="bi bi-circle-fill mx-2 "
                      style={{ fontSize: "8px" }}
                    />{" "}
                  </li>
                  <li>
                    <p className="h6">
                      <span className="rajdhani-600 mx-1">
                        Wagyu Key Gen GUI -{" "}
                        <a href="#" className="text-blue">
                          Adding a withdrawal address with Wagyu Key Gen{" "}
                          <i className="bi bi-arrow-up-right m-1" />
                        </a>
                      </span>{" "}
                    </p>
                  </li>
                </ul>
                <ul className="d-flex mx-2">
                  <li>
                    <i
                      className="bi bi-circle-fill mx-2 "
                      style={{ fontSize: "8px" }}
                    />{" "}
                  </li>
                  <li>
                    <p className="h6">
                      <span className="rajdhani-600 mx-1">
                        ethdo -{" "}
                        <a href="#" className="text-blue">
                          Changing withdrawal credentials{" "}
                          <i className="bi bi-arrow-up-right m-1" />
                        </a>
                      </span>
                    </p>
                  </li>
                </ul>
                <p className="h6">
                  These tools will assist you in generating the necessary keys
                  and message to sign. Signed messages can then be{" "}
                  <a href="" className="text-blue">
                    {" "}
                    broadcast for inclusion into blocks.{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>
                </p>
                <div
                  className="rounded-6 mt-5 p-3 contact-form style1"
                  style={{
                    // backgroundColor: "rgb(255 172 64 / 57%)",
                    backgroundColor: "#f39f1b",
                    // border: "1px solid #ffffff",
                  }}
                >
                  <div className="col-12">
                    <p className="h6">
                      This requires use of your mnemonic seed phrase, and should
                      be performed on an{" "}
                      <span className="rajdhani-600"> offline </span>air-gapped
                      machine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <h5 className="h5 pt-4 rajdhani-600">
            BLS To Execution Change (BTEC) queue
          </h5>
          <p className="h6">
            BLS withdrawal credentials (0x00) can be updated to an execution
            address (0x01) by broadcasting a BLS To Execution Change (BTEC)
            message, signed with your BLS withdrawal key. These messages are
            included in blocks at a max rate of 16 per block.
          </p>
          <p className="h6">
            If more than 16 requests are being made at one time, a queue will be
            formed and these will be processed in subsequent blocks. Once
            completed, your validator is permanently enabled for withdrawals,
            and will be eligible for payouts during the next sweep.
          </p>

          <h5 className="h4 pt-4 rajdhani-600 mb-4">
            <a href="#" className="text-white"></a>
            Excess balance withdrawals (partial withdrawals){" "}
          </h5>
          <p className="h6">
            An excess balance withdrawal is processed when an active validator
            has a maxed out effective balance of 10,000 NET1, and has a total balance
            over 10,000 NET1. A single validator cannot get rewards on excess balance
            over 10,000 NET1, and thus these accounts will have any extra balance
            automatically withdrawn to their Ethereum address.
          </p>
          <p className="h6">
            These are also referred to as “partial withdrawals” or “reward
            payments” as the remaining 10,000 NET1 stays locked and staked.
          </p>
          <div
            className="rounded-6 mt-5 p-3 contact-form style1"
            style={{
              // backgroundColor: "rgb(255 172 64 / 57%)",
              backgroundColor: "#f39f1b",
              // border: "1px solid #ffffff",
            }}
          >
            <div className="col-12">
              <p className="h6">
                📝 It is not possible to manually request specific amounts of
                 NET1 to be withdrawn
              </p>
            </div>
          </div>
          <h5 className="h4 pt-5 rajdhani-600 mb-2">
            <a href="#" className="text-white"></a>
            How to exit from staking (full withdrawals){" "}
          </h5>
          <p className="h6">
            A full withdrawal is processed for any inactivated validators that
            are no longer considered to be staking, that have fully exited from
            their validation responsibilities. Thus for a validator to fully
            withdraw its balance, it must first complete the process of exiting.
          </p>
          {/*  */}
          <div className="row justify-content-center mt-3">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-5 pt-0 mt-3">
                <h5 className="h6 text-white rajdhani-600 lh-1 m-0 pt-5 ">
                  To exit staking and fully withdrawal your entire remaining
                  balance, you must do two things:
                </h5>
                <ul className="d-flex mt-3">
                  <li>
                    <p className="rajdhani-600 mx-3 h6">1. </p>
                  </li>
                  <li>
                    <p className="h6">
                      Make sure you’ve updated your withdrawal credentials with
                      an execution withdrawal address
                    </p>
                  </li>
                </ul>
                <ul className="d-flex ">
                  <li>
                    <p className="rajdhani-600 mx-3 h6">2. </p>
                  </li>
                  <li>
                    <p className="h6">
                      Signal your intent to exit staking by signing and
                      broadcasting a voluntary exit message to the network using
                      your validator keys and validator client
                    </p>
                  </li>
                </ul>
                <p className="h6 mt-1">
                  By completing step one, you’ll enable withdrawals from your
                  validator account. This will automatically trigger excess
                  balance payments (partial withdrawals) to be processed, but
                  this does not automatically unlock the rest of your funds, or
                  trigger an exit from the network.
                </p>
                <p className="h6 ">
                  Those looking to exit their validator from staking and
                  withdrawal their  NET1 should check out the guide below that
                  matches your setup:
                </p>
                <h5 className="h6 k rajdhani-600 lh-1 m-0 pt-3 ">
                  Consensus clients:
                </h5>
                <p className="h6 mt-3 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Lighthouse validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Lodestar validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Nimbus validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Nimbus validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Teku validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>

                <h5 className="h6 k rajdhani-600 lh-1 m-0 pt-3 ">
                  Alternative node tooling:
                </h5>
                <p className="h6 mt-3 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a DAppNode validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a eth-docker validator{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
                <p className="h6 rajdhani-600">
                  {" "}
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />{" "}
                  <a href="#" className="text-blue">
                    Exiting a Rocket Pool minipool
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="h6 mt-5">
              To make an account eligible for a full withdrawal, the validator
              account must first be exited. Validator accounts can be exited
              from staking in two main ways: voluntarily or forcefully.
            </p>
            <ul className="d-flex">
              <li>
                <p className="h6">
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />
                </p>
              </li>
              <li>
                <p className="h6">
                  Anyone wishing to conclude their staking obligations can sign
                  a “voluntary exit” message which is then broadcast to the
                  Network1 Chain to start the process. This is a manual step.
                </p>
              </li>
            </ul>
            <ul className="d-flex">
              <li>
                <p className="h6">
                  <i
                    className="bi bi-circle-fill mx-3"
                    style={{ fontSize: "8px" }}
                  />
                </p>
              </li>
              <li>
                <p className="h6">
                  Accounts that have been slashed, or have insufficient balance,
                  will be forced to exit according to the network rules. This is
                  automatic and cannot be stopped once initiated.
                </p>
              </li>
            </ul>
            <p className="h6">
              Any exit, voluntary or not, must be processed through the exit
              queue. This is not instantaneous, and depends on how many other
              accounts are exiting at the same time.
            </p>
            <div
              className="rounded-6 mt-5 p-3 contact-form style1"
              style={{
                // backgroundColor: "rgb(255 172 64 / 57%)",
                backgroundColor: "#f39f1b",
                // border: "1px solid #ffffff",
              }}
            >
              <div className="col-12">
                <p className="h6">
                  📝 Validators still need to complete their validation duties
                  until they are exited
                </p>
              </div>
            </div>
            <p className="h6 mt-4">
              Once a validator has exited and its full balance has been
              withdrawn, any additional funds deposited to that validator will
              automatically be transferred to the withdrawal address during the
              next validator sweep. To re-stake  NET1, a new validator must be
              activated.
            </p>
          </div>
          {/*  */}
          <div>
            <h2 className="h3 text-white rajdhani-600 lh-1 m-0 pt-4">
              <a href="#" className="text-white">
                Mechanics of withdrawals: How they work
              </a>
              <hr />
            </h2>
            <h6 className="h5 pt-2 rajdhani-600">
              Withdrawals as an operation, not a new transaction type{" "}
            </h6>
            <p className="h6">
              Ethereum users are used to transactions being executed in a manual
              way—if you want to transfer funds, you have to sign a transaction
              and pay the gas.
            </p>
         
            <p className="h6">
              Instead of a new transaction type being used for stakers to
              manually request withdrawals, accounts are automatically checked
              for eligible excess balance every few days. All validators are
              checked on an endless loop, and any available rewards or exited
              funds are automatically “pushed” into a provided withdrawal
              account.
            </p>{" "}
          </div>
          {/* Withdrawal queue */}

          <h6 className="h5 p-2 rajdhani-600">Withdrawal queue </h6>
          <p className="h6">
            The withdrawal queue is automatically filled and processed by block
            proposers, who automatically check for any available payouts via a
            sweeping mechanism.
          </p>
          <p className="h6">
            On a never-ending loop, every single validator account is
            continuously evaluated for eligible  NET1 withdrawals (of which there
            are two types, more on this below). Validators are processed in
            order by index number, originally starting at 0, with each
            subsequent proposer picking up where the last one left off.
          </p>
          <p className="h6">
            When a validator is scheduled to propose the next block, it performs
            a sweep of validator accounts looking for eligible withdrawals.
            During the sweep, the validator will check a max of 16,384 accounts,
            attempting to find 16 available withdrawals to be processed in the
            next block.
          </p>
          <p className="h6">
            Like a clock hand, this process progresses in one direction, and
            when the last validator is reached, the sweep starts over again from
            the beginning. At each validator along the way, the account is
            evaluated for potential withdrawals.
          </p>
          <div
            className="rounded-6 mt-5 p-3 contact-form style1"
            style={{
              // backgroundColor: "rgb(255 172 64 / 57%)",
              backgroundColor: "#f39f1b",
              // border: "1px solid #ffffff",
            }}
          >
            <div className="col-12">
              <p className="h6">
                📝Note the “withdrawal” and “BLS To Execution Change” queues are
                independent and do not compete. Each are limited on a per-block
                basis.
              </p>
            </div>
          </div>
          {/* Exit queue and activation queue */}
          <h6 className="h4 p-2 mt-3 rajdhani-600">
            Exit queue and activation queue{" "}
          </h6>
          <p className="h6">
            As anyone who has already gone through the process of activating a
            validator knows, this process is not automatic, especially if there
            are a lot of other users trying to join at the same time. This is
            because the consensus layer uses an activation queue to limit how
            quickly new validator accounts can join the network.
          </p>
          <p className="h6">
            Similarly, there is also an exit queue, which limits how quickly
            validators can leave the network. This is for security reasons.
            Given each validator is limited to a max effective balance of 10,000
             NET1, this prevents large portions of the  NET1 from potentially being
            used in an attack and then quickly exiting from the network all at
            once.
          </p>
          <p className="h6">
            The number of validators that can be activated or exited in a given
            epoch (6.4 minutes) is determined by how many active validators are
            currently on the network.
          </p>
          <p className="h6">
            Four (4) validator exits are allowed per epoch, plus one (1) more
            for every 65,536 total active validators over 327,680. As of April
            2023 this limit is eight (8), and will increase to nine (9) if/when
            the active validator count reaches 655,360.
          </p>
          <div
            className="rounded-6 mt-3 p-3 contact-form style1"
            style={{
              // backgroundColor: "rgb(255 172 64 / 57%)",
              backgroundColor: "#f39f1b",
              // border: "1px solid #ffffff",
            }}
          >
            <div className="col-12">
              <p className="h6">
                📝Note the “activation” and “exit” queues are independent and do
                not compete. Each are limited on a per-epoch basis.
              </p>
            </div>
          </div>
          <h6 className="h5 p-2 mt-3 rajdhani-600">
            Exit epoch and withdrawable epoch
          </h6>
          <p className="h6">
            Immediately upon broadcasting a signed voluntary exit message, the
            exit epoch and withdrawable epoch values are calculated based off
            the current epoch number. These values determine exactly when the
            validator will no longer be required to be online performing
            validation, and when the validator is eligible for a full withdrawal
            respectively.
          </p>
          <div className="m-3 ">
            <p className="h6">
              {" "}
              <span className="rajdhani-600">Exit epoch </span>-epoch at which
              your validator is no longer active, no longer earning rewards, and
              is no longer subject to slashing rules.
            </p>
            <p className="h6">
              {" "}
              his epoch is determined by the first available epoch that isn't
              already maxed out with other validators exiting (rate limit
              depends on total validators on the network), and must be at least
              four (4) epochs after the exit was initiated.
            </p>
            <p className="h6">
              Up until this epoch (while "in the queue") your validator is
              expected to be online and is held to the same slashing rules as
              always. Do not turn your validator off until this epoch is
              reached.{" "}
            </p>
            <p className="h6 mt-3">
              {" "}
              <span className="rajdhani-600">Withdrawable epoch </span> - epoch
              at which your validator funds are eligible for a full withdrawal
              during the next validator sweep.{" "}
            </p>
            <p className="h6">
              This occurs 256 epochs after the exit epoch, which takes ~27.3
              hours.{" "}
            </p>
          </div>
          <h6 className="h5  mt-3 rajdhani-600">Exit queue summary</h6>
          <p className="h6">
            Once a signed voluntary exit message is broadcast, it takes:
          </p>
          <ul className="d-flex">
            <li>
              {" "}
              <i
                className="bi bi-circle-fill mx-2"
                style={{ fontSize: "8px" }}
              />
            </li>
            <li className="h6 mt-1">
              At least <span className="rajdhani-600"> ~25 minutes </span>(four
              epochs) from the current epoch before reaching the exit epoch
              (with no others in the queue, highly variable)
            </li>
          </ul>
          <ul className="d-flex">
            <li>
              {" "}
              <i
                className="bi bi-circle-fill mx-2"
                style={{ fontSize: "8px" }}
              />
            </li>
            <li className="h6 mt-1">
              Then another <span className="rajdhani-600"> ~27 hours</span> (256
              epochs) before those funds are flagged as withdrawable
            </li>
          </ul>
          <ul className="d-flex">
            <li>
              {" "}
              <i
                className="bi bi-circle-fill mx-2"
                style={{ fontSize: "8px" }}
              />
            </li>
            <li className="h6 mt-1">
              Then up to <span className="rajdhani-600"> a few more days </span>
              for the next validator sweep to execute the full withdrawal
              (assumes 0x01 withdrawal credentials)
            </li>
          </ul>
          <p className="h6">
            This timing of this last step is variable depending on validator
            index, current sweep position, and number of validators.{" "}
            <a href="#" className="text-blue">
              {" "}
              More on frequency of payouts below.
            </a>
          </p>
          <div
            className="rounded-6 mt-3 p-3 contact-form style1"
            style={{
              // backgroundColor: "rgb(255 172 64 / 57%)",
              backgroundColor: "#f39f1b",
              // border: "1px solid #ffffff",
            }}
          >
            <div className="col-12">
              <p className="h6">
                Note that once a user has 0x01 withdrawal credentials and has
                broadcast a voluntary exit, there is no further action required
                until the processing is complete.{" "}
              </p>
            </div>
          </div>
          {/*  */}
          <h6 className="h4  mt-4 mb-3 rajdhani-600">
            How each validator is evaluated for withdrawals{" "}
          </h6>
          <p className="h6 ">
            A decision tree is followed to determine what type of withdrawal
            will be initiated. If the validator being checked has  NET1 that is
            eligible to be withdrawn, it is added to the withdrawal queue. If
            there isn’t, the account is skipped.
          </p>
          <ul className="d-flex mx-2">
            <li>
              <p className="h6 rajdhani-600 mx-2">1 .</p>
            </li>
            <li>
              {" "}
              <p className="h6">Has a withdrawal address been provided?</p>
            </li>
            <br />
          </ul>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If so, move to next question
          </p>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If not, stop. No withdrawal will be processed and account skipped
          </p>

          <ul className="d-flex mx-2">
            <li>
              <p className="h6 rajdhani-600 mx-2">2 .</p>
            </li>
            <li>
              {" "}
              <p className="h6">
                Has the validator completed the exiting process?
              </p>
            </li>
            <br />
          </ul>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If so, stop. Full withdrawal processed for any remaining balance
          </p>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If not, move to next question
          </p>
          <ul className="d-flex mx-2">
            <li>
              <p className="h6 rajdhani-600 mx-2">3 .</p>
            </li>
            <li>
              {" "}
              <p className="h6">
                Is the effective balance maxed out at 10,000 NET1?
              </p>
            </li>
            <br />
          </ul>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If so, excess balance withdrawal processed
          </p>
          <p className="mx-4 h6">
            {" "}
            <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            If not, no withdrawal will be processed and account skipped
          </p>
          {/*  */}
          <h6 className="h4  mt-4 mb-3 rajdhani-600">
            What factors affect the frequency of payouts?{" "}
          </h6>
          <p className="h6 ">
            How long the cycle takes to check every account depends on:
          </p>
          <p className="h6 mx-2 mt-2">
            {" "}
            <span className="rajdhani-600 mx-2">1</span>TRate-limits set on
            withdrawal queue
          </p>
          <div className="d-flex mx-4">
            <p>
              <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            </p>
            <p className=" h6">
              {" "}
              Max withdrawals per payload: 16 (24)Maximum number of withdrawals
              that can be processed in a single block
            </p>
          </div>
          <div className="d-flex mx-4">
            <p>
              <i className="bi bi-circle mx-3 " style={{ fontSize: "8px " }} />
            </p>
            <p className=" h6">
              {" "}
              Max validators per withdrawals sweep: 16,384 (214) Maximum number
              of accounts that can be checked in a block. Stops when 16
              withdrawals are found. If 16 eligible rewards are not found in the
              first 16,384 accounts checked, the withdrawal queue for that block
              will be submitted as is, and the following proposer will pick up
              where this left off.
            </p>
          </div>
          <p className="h6 mx-2 mt-2">
            {" "}
            <span className="rajdhani-600 mx-2">2</span>Total number of
            validator accounts (index count, every validator account ever
            registered, up-only over time)
          </p>
          <p className="h6 mx-2 mt-2">
            {" "}
            <span className="rajdhani-600 mx-2">3</span>Total number of
            validator accounts that have eligible withdrawals (variable)
          </p>
          <p className="h6 mx-4 mt-2">
            {" "}
            <span className="rajdhani-600 mx-3">
              <i className="bi bi-circle mx-1 " style={{ fontSize: "8px " }} />
            </span>
            Accounts without updated withdrawal credentials will be skipped
          </p>
          <p className="h6 mx-4 mt-2">
            {" "}
            <span className="rajdhani-600 mx-3">
              <i className="bi bi-circle mx-1 " style={{ fontSize: "8px " }} />
            </span>
            Accounts that have fully withdrawn and have a zero balance will be
            skipped
          </p>
          <p className="h6 mx-4 mt-2">
            {" "}
            <span className="rajdhani-600 mx-3">
              <i className="bi bi-circle mx-1 " style={{ fontSize: "8px " }} />
            </span>
            Active accounts with an effective balance or total balance less than
            10,000 NET1 will be skipped
          </p>
          <p className="h6 mx-2 mt-2">
            {" "}
            <span className="rajdhani-600 mx-2">4</span>Number of empty slots
            (missed block opportunities, variable, typically minimal)
          </p>
          <p className="h6 mx-2 mt-2">
            {" "}
            <span className="rajdhani-600 mx-2">5</span>Consensus layer slot
            timing: 12 seconds (no plans to change)
          </p>
          <p className="h6 mt-3">
            This can be summarized to estimate the upper limit of how long a
            complete sweep takes depending on how many withdrawals need
            processing:
          </p>
          <div className="row d-flex  m-0">
            <div className="col-xl-5 col-md-5 col-sm-12 mt-3">
              <div className="  flex-column d-flex light-bg ">
                <hr />
                <h6 className="font-md  lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18 rajdhani-700 ">
                  Number of withdrawals{" "}
                  <span className="ms-auto text-white rajdhani-700">
                    {" "}
                    Time to complete
                  </span>{" "}
                </h6>
                <hr />
                <h6 className="  lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18 rajdhani-500">
                  400,000{" "}
                  <span className="ms-auto  rajdhani-500"> 3.5 days </span>
                </h6>{" "}
                <hr />
                <h6 className="font-mdrajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row  dark-text lh-18">
                  500,000
                  <span className="ms-auto  rajdhani-500">4.3 days </span>
                </h6>
                <hr />
                <h6 className="font-md rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18">
                  600,000{" "}
                  <span className="ms-auto  rajdhani-500">5.2 days </span>
                </h6>
                <hr />
                <h6 className=" rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18">
                  700,000{" "}
                  <span className="ms-auto  rajdhani-500">6.1 days </span>
                </h6>
                <hr />
                <h6 className=" rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18">
                  800,000 <span className="ms-auto  rajdhani-500">7 days </span>
                </h6>
              </div>
            </div>
            <div className="col-xl-1 col-md-1"></div>
            <div className="col-xl-5 col-md-5 col-sm-12 mt-3"></div>
          </div>
      
          <hr />
        </div>
        <Footer />
      </div>
    </>
  );
}
