import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Navbar from "./Navbar";
import Footer from "../Comman/Footer";
import { Link } from "react-router-dom";
import { appContext } from "../../AppContext";

export default function SelectClient() {
  const { validteReload } = useContext(appContext);
  const [show, setshow] = useState(false);
  useEffect(() => {
    validteReload();
  });
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{ backgroundImage: "url(assets/images/img-one/n1_network-bg.png)" }}
        // style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}
      >
        <Header />
        <Navbar />
        {!show ? (
          <div className="container mt-5 mb-5">
            <h2 className="h1 text-whites rajdhani-600 lh-1 m-0">Choose execution client</h2><br/>
            <div className="row pt-2 pb-5">
              <div className="col-12">
                <div className="subscribe-banner rounded-10 bg-black">
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <div className="rounded-6 py-3 px-0 light-bg contact-form style1">
                        <div className="row">
                          <div className="row justify-content-center">
                            <div className="col-md-11">
                              <div
                                className="rounded-6 p-5 contact-form style1"
                                style={{ backgroundColor: "rgba(255, 222, 179, 0.18)", border: "1px solid burlywood" }}
                              >
                                <p className="h6">Currently Geth is used by {">"} 66% of the network.</p>
                                <p className="h6">
                                  Client diversity is extremely important for the network health of Network1: A bug in a
                                  client with a share of over 33% can cause Network1 to go offline. If the client has a
                                  supermajority ({">"}66%), a bug could cause the chain to incorrectly split,
                                  potentially leading to slashing.
                                </p>
                                <p className="h6">
                                  If at all possible, consider running another client at this time to help protect
                                  yourself and the network.
                                </p>
                                <ul>
                                  <li>
                                    {" "}
                                    <a href="">
                                      <i class="bi bi-circle-fill m-2" style={{ fontSize: "8px" }}></i>More on client
                                      diversity<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </li>
                                  <li>
                                    {" "}
                                    <a href="">
                                      <i class="bi bi-circle-fill m-2" style={{ fontSize: "8px" }}></i>Latest data on
                                      network client usage<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="row pt-5">
                                <div className="col-12">
                                  <h2 className="h1  dark-text rajdhani-600 lh-1 mt-0 mb-2">Geth</h2>
                                  <hr />
                                  <p className="h6">One of the three original implementations of the Network1 protocol.</p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1  dark-text rajdhani-600 lh-1 mt-4 mb-2">
                                    Language information
                                  </h2>
                                  <hr />
                                  <p className="h6">
                                    Geth is written in Go, fully open source and licensed under the GNU LGPL v3.
                                  </p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1 dark-text rajdhani-600 lh-1 mt-4 mb-2">
                                    Installation
                                  </h2>
                                  <hr />
                                  <p className="h6">
                                    The Geth documentation explains how to download and install the client.
                                  </p>
                                  <p className="h6">
                                    {" "}
                                    <a href="">
                                      Geth installation documentation<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1 dark-text rajdhani-600 lh-1 mt-4 mb-2">Setup</h2>
                                  <hr />
                                  <p className="h6">
                                    Make sure you do the following to get your execution client working properly.
                                  </p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1 dark-text rajdhani-600 lh-1 mt-5 mb-4">
                                    JSON RPC endpoint
                                  </h2>
                                  <p className="h6">
                                    Use --http to connect your consensus node to the JSON RPC endpoint. This will enable
                                    the JSON RPC services on the default 8545 port.
                                  </p>
                                  <p className="h6">
                                    {" "}
                                    <a href="">
                                      --http documentation<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="rounded-6 mt-5 p-3 contact-form style1"
                                style={{ backgroundColor: "rgba(255, 222, 179, 0.18)", border: "1px solid burlywood" }}
                              >
                                <div className="col-12">
                                  <h2 className="h4 dark-text rajdhani-600 lh-1 mt-0 mb-4">Remember</h2>
                                  <p className="h6">
                                    After client installation, ensure you are fully synced before submitting your
                                    staking deposit. This can take several days. <a href="">Validator checklist</a>{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br/>
              </div>
              <div className="row justify-content-center">
                <div class="col-md-10"> 
                  <div className="row justify-content-center">
                    <div class="col-md-3">
                      <Link
                        to="/overview"
                        className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                        // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                      >
                        {" "}
                        Back
                      </Link>
                    </div>
                    <div class="col-md-3">
                      <button
                        onClick={() => setshow(true)}
                        className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                        // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                      >
                        {" "}
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container mt-5 mb-5">
            <h2 className="h1 text-white rajdhani-600 lh-1 m-0">Choose consensus client</h2>
            <div className="row pt-2 pb-5">
              <div className="col-12">
                <div className="subscribe-banner rounded-10 bg-black">
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <div className="rounded-6 py-3 px-0 light-bg  contact-form style1">
                        <div className="row">
                          <div className="row justify-content-center">
                            <div className="col-md-11">
                              <div
                                className="rounded-6 p-5 contact-form style1"
                                style={{ backgroundColor: "rgba(255, 222, 179, 0.18)", border: "1px solid burlywood" }}
                              >
                                <p className="h6">Currently Prysm is used by {">"} 33% of the network.</p>
                                <p className="h6">
                                  Client diversity is extremely important for the network health of Network1: A bug in a
                                  client with a share of over 33% can cause Network1 to go offline. If the client has a
                                  supermajority ({">"}66%), a bug could cause the chain to incorrectly split,
                                  potentially leading to slashing.
                                </p>
                                <p className="h6">
                                  If at all possible, consider running another client at this time to help protect
                                  yourself and the network.
                                </p>
                                <ul>
                                  <li>
                                    {" "}
                                    <a href="">
                                      <i class="bi bi-circle-fill m-2" style={{ fontSize: "8px" }}></i>More on client
                                      diversity<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </li>
                                  <li>
                                    {" "}
                                    <a href="">
                                      <i class="bi bi-circle-fill m-2" style={{ fontSize: "8px" }}></i>Latest data on
                                      network client usage<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="row pt-5">
                                <div className="col-12">
                                  <h2 className="h1  dark-text rajdhani-600 lh-1 mt-0 mb-4">Prysm</h2>
                                  <hr />
                                  <p className="h6">
                                    An implementation of the consensus protocol with a focus on usability, security, and
                                    reliability. Prysm is developed by Prysmatic Labs, a company with the sole focus on
                                    the development of their client.
                                  </p>
                                  <p className="h6">
                                    {" "}
                                    <a href="https://prysmaticlabs.com/" target="_blank">
                                      More on Prysmatic Labs<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1  dark-text rajdhani-600 lh-1 mt-4 mb-1">
                                    Language information
                                  </h2>
                                  <hr />
                                  <p className="h6">Prysm is written in Go and released under a GPL-3.0 license.</p>
                                </div>
                                <div className="col-12">
                                  <h2 className="h1  dark-text rajdhani-600 lh-1 mt-4 mb-2">
                                    Become a validator with Prysm
                                  </h2>
                                  <hr />
                                  <p className="h6">
                                    {" "}
                                    Prysm offers step-by-step guidelines to run their client after completing your
                                    deposit through the launchpad.
                                    <a
                                      href="https://docs.prylabs.network/docs/install/install-with-script"
                                      target="_blank"
                                    >
                                      {" "}
                                      Prysm installation documentation<i class="bi bi-arrow-up-right m-2"></i>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="rounded-6 p-4 contact-form style1"
                                style={{ backgroundColor: "rgba(255, 222, 179, 0.18)", border: "1px solid burlywood" }}
                              >
                                <h2 className="h4  dark-text rajdhani-600 lh-1 mt-0 mb-4">Remember</h2>
                                <p className="h6">
                                  After client installation, ensure you are fully synced before submitting your staking
                                  deposit. This can take several days.
                                  <a href="">Validator checklist</a>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br/>
              </div>
              <div className="row justify-content-center">
                <div class="col-md-10">
                  <div className="row justify-content-center">
                    <div class="col-md-3">
                      <button
                        onClick={() => setshow(false)}
                        className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                        // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                      >
                        {" "}
                        Back
                      </button>
                    </div>
                    <div class="col-md-3">
                      <Link
                        to="/generate-key"
                        className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                        // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                      >
                        {" "}
                        Continue
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
}
