import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <>
      <div
        className="banner-wrapper style13 bg-image-none-dark py-4 dark-bg pt-100 bg-image-top "
        style={{ backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",}}
      >
        <div className="container-wide py-xl-5">
          <div className="row justify-content-between">
            <div className="col-lg-7 col-md-6 mt-md-4 md-mb-3">
              <h1 className="display5-size lh-1 text-gray-900 dark-text rajdhani-700">
                Become a validator and help secure the future of Network1
              </h1>
              <p className=" dark-text h6 lh-28 text-gray-700 w-xl-75 mt-2">
                Earn continuous rewards for providing a public good to the community.
              </p>
              {/* <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                  <Link
                    to="/overview"
                    className="d-lg-none d-md-none d-xl-block d-sm-none d-col-none d-none btn btn-lg  text-gray-900 rajdhani-700 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                  >
                    BECOME A VALIDATOR
                  </Link>{" "}
                  <br />
                  <a
                    href="/desktop"
                    className="d-lg-block d-md-block d-xl-none d-sm-block  d-block btn btn-lg  text-gray-900 rajdhani-700  rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                  >
                    BECOME A VALIDATOR
                  </a>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase ">THINKING ABOUT STAKING?</a> */}
                  {/* <a
                    href="/overview"
                    className="d-lg-block d-md-block d-xl-block mt-2 btn btn-lg w-100  text-gray-900 rajdhani-700 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm text-uppercase"
                  >
                    THINKING ABOUT STAKING?
                  </a>{" "}
                  <br />
                </div>
              </div>  */}
              {/* <div className="d-flex flex-row gap-3 mt-4 pt-3">
                            </div> */}
     <div className="fugu--btn-wrap  fugu--hero-btn">
              <a className="fugu--btn bg-gradient" href="" style={{color:"white"}}>
              BECOME A VALIDATOR
              </a>
              <a className="fugu--btn bg-gradient" href="" style={{color:"white"}} >
              THINKING ABOUT STAKING?
              </a>
            </div>

            </div>
            <div className="col-lg-5 col-md-6 ps-md-4 posr md-mt-4 pt-100">
              <img src="assets/images/img-one/Become-a-validator-and-help-secure-the-future-of-Network1.png" alt="banner" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
