import React from 'react'
import Footer from '../Comman/Footer'

export default function Desktop() {
  return (
    <>
    <div className="main-wrapper dark-bg3 pb-120"
        style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}>
          <div className="container text-center">
            <h1 className='h1   mt-150 rajdhani-600'>Desktop only</h1>
            <p className='h6'>You must be on a desktop device to use this app.</p>
          </div>
          <div className="row justify-content-center">
          <div className="col-11">
          <div
                  className="rounded-6 mt-5 p-3 contact-form style1"
                  style={{
                    backgroundColor: "rgb(240, 244, 248)",
                    border: "1px solid rgb(182, 182, 182)",
                  }}
                >
                 <div className="row text-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <button className=" w-120 h-120 alert-success border-0 text-warning rounded-pill mt-3" style={{backgroundColor:"rgb(255, 155, 154)"}}>
                              <img
                                src="assets/images/mobile.svg"
                                className="w-70"
                                alt=""
                              />
                            </button><br />
                            <i className="bi bi-exclamation-triangle " style={{fontSize:"45px",color:"rgb(255, 155, 154)"}}/>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <button className=" w-120 h-120 alert-success border-0 text-warning rounded-pill mt-3"style={{backgroundColor:"rgb(208, 225, 213)"}} >
                              <img
                                src="assets/images/laptop.svg"
                                className="w-70"
                                alt=""
                              />
                            </button> <br />
                            <i className="bi bi-check2 text-green " style={{fontSize:"45px"}}/>
                  </div>
                 </div>
                    </div>
                    <div className="col-12 mt-5">
                                <a href="/" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase w-100">CLOSE</a>
                        </div>
                  </div>
                </div>
        </div>
      <Footer/>
    </>
  )
}
