import React, { useContext, useState } from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../AppContext";

export default function Overview() {
  const { show, setshow, handleTabShow, handleTabs } = useContext(appContext);

  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        // style={{
        //   background:
        //     "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)",
        // }}
        style={{
          backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        }}
      >
        <Header />
        <Navbar />
        <div className="container mt-5">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">Advisories</h2>
          <p className="lh-26 h5  mt-3 " style={{ color: "white" }}>
            Everything you should understand before becoming a validator.
          </p>
          <br />
          <div className="row pt-2 pb-5">
            <div className="col-12">
              <div className="subscribe-banner rounded-10 bg-black">
                {/* <div className="row justify-content-center">
                                    <div className="row">
                                        <div className="col-sm-9">
                                        </div>
                                    </div>
                                    <div className="col-lg-8 text-center">
                                        <h2 className="h1 text-black rajdhani-600 lh-1 m-0">Create your own NFT’s</h2>
                                    </div>
                                </div> */}
                <div className="row">
                  <div className="col-3 mt-2 ms-4">
                    <div
                      className={`form-radio ${
                        show[0].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-visa"
                        checked={show[0].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around align-items-center position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-visa"
                        onClick={() => handleTabShow(0, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">1</span>
                        </div>
                        <span className="mt-2 mb-2 ms-3 h6 rajdhani-600 text-start">
                          Proof of Network1
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[1].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[1].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(1, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">2</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          Deposit
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[2].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[2].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(2, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">3</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          The terminal
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[3].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[3].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(3, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">4</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          Uptime
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[4].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[4].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(4, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">5</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          Bad behavior
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[5].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[5].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(5, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">6</span>
                        </div>
                        <span className="mt-2 mb-2 ms-3 h6 dark-text   rajdhani-600 text-start">
                          Key management
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[6].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[6].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(6, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">7</span>
                        </div>
                        <span className="mt-2 mb-2 h6 ms-4 dark-text   rajdhani-600 text-start">
                          Early adoption risks
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[7].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[7].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(7, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">8</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          Checklist
                        </span>
                      </label>
                    </div>
                    <br />
                    <div
                      className={`form-radio ${
                        show[8].tab ? "sidetab-active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name="bank"
                        id="bank-card"
                        checked={show[8].checked}
                      />
                      <label
                        className="border-light dark-border border payment-check check-top btn-md btn-block rounded d-flex flex-row justify-content-around position-relative border-2 border-light inter-600 text-gray-700 text-start"
                        htmlFor="bank-card"
                        onClick={() => handleTabShow(8, true)}
                      >
                        <div className="text-number">
                          <span className="my-2">9</span>
                        </div>
                        <span className="mt-2 mb-2 h6 dark-text   rajdhani-600 text-start">
                          Confirmation
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className={show[0].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Proof of Network1
                      </h2>
                      <p className="h6">
                        Network1 uses proof-of-network (PoN) to reach consensus.
                      </p>
                      <p className="h6">
                        In this process, we need active participants – also
                        called as validators – to propose, verify, and vouch for
                        the validity of blocks and transactions. In exchange,
                        these validators receive block mining rewards in NET1
                        tokens.
                      </p>
                      <p className="h6">
                        Importantly, as a validator you'll need to provide NET1
                        as collateral – in other words, have some funds at
                        stake. The only way to become a validator is to make a
                        one-way NET1 deposit transaction to the staking contract
                        on the Mainnet execution layer. The Network1 Chain
                        (consensus layer) is used in parallel to keep track of
                        all validator activity.
                      </p>
                      <br />
                      <a href="">Read more about Network1 Chain</a>
                      <div
                        className="rounded-6 p-4 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand and agree to the terms-of-service and
                          everything stated in the previous sections.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(0, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[1].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        The deposit
                      </h2>
                      <p className="h6">
                        To become a validator on the Network1 Chain, you need to
                        deposit 10,000 NET1 per validator that you wish to run
                      </p>
                      <p className="h6">
                        Deposit transactions are non-reversible, but you have
                        the ability to withdraw funds via a separate process as
                        deposit remains under your control.
                      </p>
                      <div
                        className="rounded-6 p-4 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand that I need to deposit 10,000 NET1 onto
                          the Network1 Chain to become a validator. Withdrawing
                          deposited NET1 from the Network1 Chain is accomplished
                          via a separate process.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(0, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(1, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[2].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Using the terminal
                      </h2>
                      <p className="h6">
                        To become a validator, you will need to be able to run
                        commands in the terminal on your computer.
                      </p>
                      <p className="h6">
                        Generating your new key pairs and installing the
                        validator software are both done in the terminal.
                      </p>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I am technically capable of setting up and running a
                          validator.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(1, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(2, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[3].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Validator uptime
                      </h2>
                      <p className="h6">
                        You'll only get your full rewards if your validator is
                        online and up to date. This is your responsibility. If
                        your validator goes offline you'll be penalized. The
                        penalties for being offline are roughly equal to the
                        rewards for actively participating.
                      </p>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand that it is important to keep my validator
                          online and updated.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(2, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(3, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[4].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Bad validator behavior
                      </h2>
                      <p className="h6">
                        If you try to cheat the system or act contrary to the
                        specification, you will be liable to incur a penalty
                        known as slashing. Running your validator keys
                        simultaneously on two or more machines will result in
                        slashing.
                      </p>
                      <p className="h6">
                        Simply being offline with an otherwise healthy network
                        does not result in slashing, but will result in small
                        inactivity penalties.
                      </p>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand that if I act contrary to the
                          specification, I am liable to be slashed.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(3, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(4, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[5].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Key management
                      </h2>
                      <p className="h6">
                        To become a validator you'll need to know about managing
                        keys and protecting a mnemonic. If you are not yet
                        familiar with keys and mnemonics, please do not proceed.
                      </p>
                      <p className="h6">
                        We'll help you create a signing key for every validator
                        you want to run. You may choose to provide a withdrawal
                        address for your validator when generating your deposit
                        data, which will permanently set the withdrawal address.
                        This is recommended for most users.
                      </p>
                      <p className="h6">
                        If you do not provide a withdrawal address with your
                        initial deposit data, you will need to derive your
                        withdrawal keys from your mnemonic at a later time, so
                        store your mnemonic safely—it will be the ONLY way to
                        withdraw your NET1 when you choose to activate
                        withdrawals.
                      </p>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand that keys are my responsibility and that
                          my mnemonic (seed) will be the
                          <strong> ONLY WAY </strong>to withdraw my funds if I
                          don't provide a withdrawal address with initial
                          deposit data.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(4, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(5, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[6].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Early adopter risks
                      </h2>
                      <p className="h6">
                        You're joining a network in its early stages. As with
                        any new piece of software, there is the potential for
                        software bugs. While unlikely, potential bugs may result
                        in slashing.
                      </p>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I am an early adopter, and I accept that software and
                          design bugs may result in me being slashed.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(5, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(6, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[7].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Staking checklist
                      </h2>
                      <p className="h6">
                        Please review the staking checklist prior to proceeding.
                        Use this as a guide to check off tasks as you complete
                        validator setup.
                      </p>
                      <a href="">Validator checklist</a>
                      <p className="h6">
                        Also be sure you understand how staking withdrawals work
                        before setting up your keys and deposit data.
                      </p>
                      <a href="">Staking withdrawals</a>
                      <div
                        className="rounded-6 pt-4 pb-2 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I have reviewed the checklist and understand how
                          withdrawals work.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(6, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(7, true)}
                              >
                                {" "}
                                I accept
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={show[8].tab ? "" : "d-none"}>
                      <h2 className="h1   dark-text rajdhani-600 lh-1 mt-0 mb-4">
                        Confirmation
                      </h2>
                      <p className="h6">
                        I have read and agree to the Launchpad terms of service.
                      </p>
                      <a href="">Terms of service</a>

                      <div
                        className="rounded-6 pt-4 pb-2 px-4 contact-form style1 mt-200"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <p
                          className="fs-6 text-center"
                          style={{ color: "white" }}
                        >
                          I understand and agree to the terms-of-service and
                          everything stated in the previous sections.
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div class="col-md-10">
                          <div className="row justify-content-center">
                            <div class="col-md-4">
                              <a
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabShow(7, true)}
                              >
                                {" "}
                                Back
                              </a>
                            </div>
                            <div class="col-md-8">
                              <a
                                type="button"
                                className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                                style={{ color: "white" }}
                                // className="btn btn-lg btn-block   rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                                onClick={() => handleTabs(8, true)}
                              >
                                {" "}
                                Continue
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
