import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

export default function Topup() {
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        }}
        // style={{
        //   background: "black"}}
        //     "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)",}}
      >
        <Header />
        <br />
        <br />
        <div className="container  mt-xl-5 mt-lg-5">
          <br />
          <br />
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Top up a validator
          </h2>
          <p className="h6 pt-4">
            You may need to top up your validator's balance for two important
            reasons. If your validator's effective balance is below 10,000 ETH you
            won't be earning your full staker rewards. And if it drops as low as
            5,000 ETH the system will eject your validator.
          </p>
          <div
            className="rounded-6 mt-5 p-3 contact-form style1"
            style={{
              // backgroundColor: "rgb(255 172 64 / 57%)",
              // border: "1px solid rgb(255, 163, 27)",
              backgroundColor: "#f39f1b",
              border: "1px solid #ffffff",
            }}
          >
            <div className="col-12">
              <p className="h6">
               10,000 NET1 is the maximum effective validator balance. This means
                you won't earn more rewards if your validator's balance goes
                above10,000. However you will earn less if it dips below10,000.{" "}
                <a href="#" className="text-blue">
                  {" "}
                  More on effective balance{" "}
                  <i className="bi bi-arrow-up-right m-1" />
                </a>
              </p>
            </div>
          </div>
          {/* <div className="text-center mt-5 mb-3">
            <a
              href="#"
              className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-md text-uppercase"
            >
              CONNECT WALLET
            </a>
          </div> */}
          <div className="fugu--btn-wrap  fugu--hero-btn text-center">
            <a
              className="fugu--btn bg-gradient"
              href="#"
              style={{ color: "white" }}
            >
              CONNECT WALLET
            </a>
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}
