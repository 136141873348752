import React, { useContext, useEffect, useState, useTransition } from "react";
import Header from "../Comman/Header";
import Navbar from "./Navbar";
import Footer from "../Comman/Footer";
import { Link, useNavigate } from "react-router-dom";
import { appContext } from "../../AppContext";

export default function Connectwallet() {
  const navigate = useNavigate();
  const [disButton, setDisButton] = useState(false);
  const {
    open,
    connectedAddress,
    isConnected,
    chainId,
    formatAddress,
    balance,
    validators,
    withdrawalAddress,
    validteReload,
  } = useContext(appContext);

  useEffect(() => {
    if (connectedAddress) {
      if (validators * 32 < Number(balance)) {
        setDisButton(false);
      } else {
        setDisButton(true);
      }
    } else {
      setDisButton(true);
    }
  }, [balance, connectedAddress]);

  useEffect(() => {
    validteReload();
  });
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{ backgroundImage: "url(assets/images/img-one/n1_network-bg.png)" }}
        // style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}
      >
        <Header />
        <Navbar />
        <div className="container mt-5 mb-5">
          <h2 className="h1 text-white text-center rajdhani-600 lh-1 m-0">Connect wallet</h2><br/>
          {/*  */}
          {isConnected ? (
            ""
          ) : (
            <div className="row justify-content-center">
              <div className="col-5">
                <div className=" rounded-10 bg-black p-4 mt-3 cursor-pointer" onClick={open}>
                  <div class="row ">
                    <div className="col-12 ">
                      <ul className="d-flex justify-content-around align-items-center">
                        <li>
                          <img src="./assets/images/icon-14.png" alt="" srcset="" />
                        </li>
                        <li>
                          <h2 className="h3 text-white rajdhani-600 lh-1 m-0"> Metamask </h2>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center">
            {isConnected ? (
              <div className="col-10">
                <div className=" rounded-10 bg-white p-4 mt-3">
                  <div class="row ">
                    <div className="col-12 ">
                      <ul className="d-flex justify-content-between">
                        <li>
                          <h2 className="h4 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">Metamask</h2>
                        </li>
                        <li>
                          <h2 className="h4 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                            <span className="h6">{formatAddress(connectedAddress)}</span>
                          </h2>
                        </li>
                      </ul>
                      <hr />
                    </div>
                    <div className="col-12 ">
                      <ul className="d-flex justify-content-between">
                        <li>
                          <h2 className="h5 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                            {chainId === 17000 ? (
                              <i className="bi bi-check-circle-fill text-success m-2 " />
                            ) : (
                              <i class="bi bi-x-circle-fill text-red m-1"></i>
                            )}
                            Network
                          </h2>
                        </li>
                        <li>
                          <h2 className="h5 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                            <span className={`h6 ${chainId === 17000 ? "text-success" : "text-danger"}`}>
                              Holesky testnet
                            </span>
                          </h2>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 ">
                      <ul className="d-flex justify-content-between">
                        <li>
                          <h2 className="h5 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                            {validators * 32 < Number(balance) ? (
                              <i className="bi bi-check-circle-fill text-success m-2 " />
                            ) : (
                              <i class="bi bi-x-circle-fill text-red m-1"></i>
                            )}
                            Balance
                          </h2>
                        </li>
                        <li>
                          <h2 className="h5 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                            <span className="h6">{Number(balance).toFixed(4)} TestnetNET1</span>
                          </h2>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {validators * 32 < Number(balance) ? (
                    ""
                  ) : (
                    <>
                      <p className="h6 pt-4">
                        You do not have enough TestnetNET1 in this account for {validators} validator
                      </p>
                      <p className="h6 pt-1">
                        <a href="">
                          Get TestnetNET1<i class="bi bi-arrow-up-right m-2"></i>
                        </a>
                      </p>
                    </>
                  )}
                  {withdrawalAddress ? (
                    ""
                  ) : (
                    <div
                      className="rounded-6 mt-5 p-3 contact-form style1"
                      style={{
                        backgroundColor: "rgba(255, 222, 179, 0.18)",
                        border: "1px solid burlywood",
                      }}
                    >
                      <div className="col-12">
                        <p className="h6">
                          A withdrawal address has not been set for these validators. Staked funds and rewards will
                          remain locked until withdrawal credentials are provided.{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row justify-content-center">
              <div class="col-md-10">
                <div className="row justify-content-center mt-5">
                  <div class="col-md-3">
                    <Link
                      to="/upload-deposit-data"
                      className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                      // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                    >
                      Back
                    </Link>
                  </div>
                  <div class="col-md-3">
                    <button
                    className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                      // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                      onClick={() => navigate("/transactions")}
                      disabled={disButton}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </div>
        <Footer />
      </div>
    </>
  );
}
