import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

export default function Checklist() {
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          backgroundColor:"black"
          // backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        }}
        // style={{
        //   background:
        //     "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)",
        // }}
      >
        <Header />
        {/* <Navbar /> */}
        <div className="container mt-xl-5 mt-lg-5 ">
          <br />
          <br />
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Validator checklist
          </h2>
          <p className="h6 pt-4 rajdhani-600">
            This checklist will help you understand the role of a validator and
            prepare you for the role.{" "}
          </p>
          <p className="h6  text-gray-600 ">
            Visit EthStaker on Discord or Reddit at any time during your setup
            for some friendly help!{" "}
          </p>
          {/*  */}
          <div className="row justify-content-center">
            <div className=" col-md-4 col-sm-12">
              <a href="#Section-one">
                <div className="  bg-black p-3 mt-3 rounded-10 section-1">
                  <ul className="d-flex justify-content-between pt-3 h6">
                    <tr>
                      <li>
                        <p className="h6  rajdhani-600">Section 1</p>
                      </li>
                      <li>
                        <h2 className="h4 text-skype rajdhani-600 lh-1 m-0">
                          Before you start
                        </h2>
                      </li>
                    </tr>
                    <tr>
                      <li>
                        <h2>
                          <i class="bi bi-chevron-right"></i>
                        </h2>
                      </li>
                    </tr>
                  </ul>
                </div>
              </a>
            </div>
            <div className=" col-md-4 col-sm-12">
              <a href="#Section-two">
                <div className="  bg-black p-3 mt-3 rounded-10  section-1">
                  <ul className="d-flex justify-content-between pt-3 h6">
                    <tr>
                      <li>
                        <p className="h6  rajdhani-600">Section 2</p>
                      </li>
                      <li>
                        <h2 className="h4 text-skype rajdhani-600 lh-1 m-0">
                          During setup
                        </h2>
                      </li>
                    </tr>
                    <tr>
                      <li>
                        <h2>
                          <i class="bi bi-chevron-right"></i>
                        </h2>
                      </li>
                    </tr>
                  </ul>
                </div>
              </a>
            </div>
            <div className="col-md-4 col-sm-12">
              <a href="#Section-three ">
                <div className="  bg-black p-3 mt-3 rounded-10  section-1">
                  <ul className="d-flex justify-content-between pt-3 h6 ">
                    <tr>
                      <li>
                        <p className="h6  rajdhani-600">Section 3</p>
                      </li>
                      <li>
                        <h2 className="h4 text-skype rajdhani-600 lh-1 m-0">
                          After depositing
                        </h2>
                      </li>
                    </tr>
                    <tr>
                      <li>
                        <h2>
                          <i class="bi bi-chevron-right"></i>
                        </h2>
                      </li>
                    </tr>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          {/* section 1 start */}
          <div>
            <h5 className="h5 text-white rajdhani-600 mt-5" id="Section-one">
              Section 1 - Before you start
            </h5>
            <p className="h6 ">
              Review this section before deciding to proceed with validator
              setup.
            </p>
            <div
              className="rounded-6 mt-5 p-3 contact-form style1"
              style={{
                // backgroundColor: "rgb(255 172 64 / 57%)",
                backgroundColor: "#f39f1b",
                border: "1px solid #ffffff",
              }}
            >
              <div className="col-12">
                <h5 className="h6 text-black rajdhani-600 mt-3">
                  Recommendation disclaimer
                </h5>
                <p className="h6">
                  Hardware suggestions are an ever-evolving target. Current
                  minimum requirements are likely to increase by an order of
                  magnitude after the introduction of Danksharding. Do your own
                  research before depositing funds.
                </p>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <div class="row "></div>
                  <h5 className="h5 pt-4">Hard drive</h5>
                  <hr />
                  <p className="h6 pt-1">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                      You need to run an{" "}
                      {/* <a href="#" className="text-black rajdhani-600"> */}
                        exexution client{" "}
                        {/* <i className="bi bi-arrow-up-right m-1" /> */}
                      {/* </a>{" "} */}
                    </span>
                    <span>
                      {" "}
                      as well as your
                      {/* <a href="#" className="text-black rajdhani-600"> */}
                        {" "}
                        consensus client.{" "}
                        {/* <i className="bi bi-arrow-up-right m-1" />
                      </a>{" "} */}
                    </span>
                  </p>
                  <ul className="d-flex">
                    <li>
                      {" "}
                      <span>
                        <i
                          className="bi bi-circle-fill m-2"
                          style={{ fontSize: "8px" }}
                        />{" "}
                      </span>
                    </li>
                    <li>
                      <p className="h6">
                        Since the Merge, third-party providers (such as Infura
                        and Alchemy) are no longer viable options to outsource
                        execution layer responsibilities. All stakers must run
                        both an execution and a consensus client to properly
                        attest to the network.
                      </p>
                    </li>
                  </ul>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    As of August 2024, you'll need ~1TB for the Mainnet execution
                    chain data alone (growing at {">"} 1GB/day).{" "}
                  </p>
                  <ul className="d-flex">
                    <li>
                      {" "}
                      <span>
                        <i
                          className="bi bi-circle-fill m-2"
                          style={{ fontSize: "8px" }}
                        />{" "}
                      </span>
                    </li>
                    <li>
                      <p className="h6">
                        Network1 had its genesis in August 2024, It is growing
                        in size over time, and the introduction of Danksharding
                        will also increase storage, memory, and bandwidth
                        requirements.
                      </p>
                    </li>
                  </ul>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    You'll need SSD storage to consistently handle necessary
                    read/write speeds.
                  </p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Be sure to account for enough space on your drive until you
                    run maintenance on your node.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <div class="row "></div>
                  <h5 className="h5 pt-4">CPU and RAM</h5>
                  <hr />
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Check with client documentation to ensure the hardware you
                    want to use is sufficient and supported.{" "}
                  </p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Resource usage can vary significantly between clients.
                    Research the different clients if you're working with
                    resource constraints.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <div class="row "></div>
                  <h5 className="h5 pt-4">Internet</h5>
                  <hr />
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Ideally your internet connection should be reliable and as
                    close to 24/7 as possible without interruption.{" "}
                  </p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Ensure your bandwidth can't be throttled and isn't capped so
                    your node stays in sync and will be ready to validate when
                    called.{" "}
                  </p>
                  <ul className="d-flex">
                    <li>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      <p className="h6">
                        You need enough upload bandwidth too. As of August 2024,
                        this is ~1.2-1.3 GB download and ~0.9-1 GB upload per
                        hour, and is likely to increase.
                      </p>
                    </li>
                  </ul>
                  <h5 className="h5 pt-4">Notes</h5>
                  <ul className="d-flex pt-2">
                    <li>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      <p className="h6">
                        Avoid overly-complicated setups and be aware of trade
                        offs. Being offline for brief periods of time will
                        result in small inactivity penalities, but will be
                        recouped easily after being online again for about the
                        same amount of time. Complicated power backups can add
                        to the expense of your setup, and redundant backup
                        validators can lead to a more serious penalty known as
                        slashing.
                      </p>
                      <a href="" className="text-blue rajdhani-500 h6">
                        {" "}
                        More on slashing risks
                      </a>
                    </li>
                  </ul>
                  <p className="h6 mt-1">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Syncing your execution client may take a few days in the
                    worst-case scenario.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* section 1 end */}
          {/* section 2 start */}
          <div>
            <h5 className="h5 text-white rajdhani-600 mt-5" id="Section-two">
              Section 2 - During setup
            </h5>
            <p className="h6 ">
              Use this as a reference during client setup to check off important
              steps.
            </p>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Initial setup</h5>
                  <hr />
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Start by setting up your chosen hardware and operating
                    system.
                  </p>
                  <ul className="d-flex">
                    <li>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      <p className="h6">
                        To maximize security and efficiency of your node, use
                        dedicated hardware to run your clients. This reduces
                        risk of malware exposure and minimizes competition for
                        computing resources, ensuring your node handles the
                        network load and its validator responsibilities at all
                        times.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Node security</h5>
                  <hr />

                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    className="form-check-input m-2"
                    defaultValue="Bike"
                  />
                  <label htmlFor="vehicle1" className="m-2 h6">
                    {" "}
                    I've secured the root account
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    id="vehicle2"
                    name="vehicle2"
                    className="form-check-input m-2"
                    defaultValue="Car"
                  />
                  <label htmlFor="vehicle2" className="h6 m-2">
                    {" "}
                    I've set up a firewall.
                  </label>
                  <br />
                  <ul className="d-flex">
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        className="form-check-input m-2"
                        defaultValue="Boat"
                      />
                    </li>
                    <li>
                      {" "}
                      <label htmlFor="vehicle3" className="h6 m-2">
                        I've forwarded the necessary ports to the correct
                        machine(s) from my router for both my EL and CL client
                        (only open the ports that apply to your installations).
                      </label>
                    </li>
                  </ul>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Start by setting up your chosen hardware and operating
                    system.
                  </p>
                  <div className="row d-flex  m-lg-5 m-0">
                    <div className="col-xl-5 col-md-5 col-sm-12 mt-3">
                      <div className="  flex-column d-flex light-bg ">
                        <hr />
                        <h6 className="h5  lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18 rajdhani-700 ">
                          Execution Client{" "}
                          <span className="ms-auto text-black rajdhani-700">
                            {" "}
                            Default Port
                          </span>{" "}
                        </h6>
                        <hr />
                        <h6 className="  lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18 rajdhani-500">
                          Nethermind{" "}
                          <span className="ms-auto  rajdhani-500">
                            {" "}
                            30303 TCP/UDP{" "}
                          </span>
                        </h6>{" "}
                        <hr />
                        <h6 className="font-mdrajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row  dark-text lh-18">
                          Erigon
                          <span className="ms-auto  rajdhani-500">
                            30303 TCP/UDP{" "}
                          </span>
                        </h6>
                        <hr />
                        <h6 className="font-md rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18">
                          Besu{" "}
                          <span className="ms-auto  rajdhani-500">
                            30303 TCP/UDP{" "}
                          </span>
                        </h6>
                        <hr />
                        <h6 className=" rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18">
                          Geth{" "}
                          <span className="ms-auto  rajdhani-500">
                            30303 TCP/UDP{" "}
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-xl-1 col-md-1"></div>
                    <div className="col-xl-5 col-md-5 col-sm-12 mt-3">
                      <div className="  flex-column d-flex light-bg ">
                        <hr />
                        <h6 className="h5  lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18 rajdhani-700 ">
                          Consensus Client{" "}
                          <span className="ms-auto text-black rajdhani-700">
                            {" "}
                            Default Port
                          </span>{" "}
                        </h6>
                        <hr />
                        <h6 className="  lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18 rajdhani-500">
                          Prysm{" "}
                          <span className="ms-auto  rajdhani-500">
                            {" "}
                            13000 TCP, 12000 UDP
                          </span>
                        </h6>{" "}
                        <hr />
                        <h6 className="font-mdrajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row  dark-text lh-18">
                          Teku
                          <span className="ms-auto  rajdhani-500">
                            9000 TCP/UDP{" "}
                          </span>
                        </h6>
                        <hr />
                        <h6 className="font-md rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row dark-text lh-18">
                          Nimbus{" "}
                          <span className="ms-auto  rajdhani-500">
                            {" "}
                            9000 TCP/UDP{" "}
                          </span>
                        </h6>
                        <hr />
                        <h6 className=" rajdhani-500 lh-1 mb-0 mt-1 d-flex flex-row font-md dark-text lh-18">
                          Lighthouse{" "}
                          <span className="ms-auto  rajdhani-500">
                            9000 TCP/UDP{" "}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <a className="sc-AxhCb bkgTsZ mt10" href="#">
                    Learn about ports in networking{" "}
                    <i className="bi bi-arrow-up-right m-1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4 rajdhani-600">Configure time sync</h5>
                  <hr />
                  <p className="h6 ">For Ubuntu 20.04</p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Run the following command:
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-11">
                      <div
                        className="rounded-6 mt-2 p-3 contact-form style1 "
                        style={{
                          border: "1px solid #ffffff",
                          // backgroundColor: "rgb(89, 126, 163)",
                        }}
                      >
                        <h5 className=" h5 text-white rajdhani-600 lh-1 m-0">
                          timedatectl
                        </h5>
                      </div>
                    </div>
                  </div>
                  <p className="h6 mt-3">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Check if NTP Service is active.:
                  </p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Check if Local time, Time zone, and Universal time are all
                    correct.:
                  </p>
                  <p className="h6">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    If NTP Serviceis not active run:
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-11">
                      <div
                        className="rounded-6 mt-2 p-3 contact-form style1 "
                        style={{
                          border: "1px solid #ffffff",
                          // backgroundColor: "rgb(89, 126, 163)",
                        }}
                      >
                        <h5 className=" h5 text-white rajdhani-600 lh-1 m-0">
                          sudo timedatectl set-ntp on
                        </h5>
                      </div>
                    </div>
                  </div>
                  <p className="h6 mt-3">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    If you see error message Failed to set ntp: NTP not
                    supported, you may need to install chrony or ntp package.
                  </p>
                  <p className="h6 mb-3">
                    <span>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </span>
                    Note: by default, VMs may disable NTP so you may need to
                    find a work-around for your environment.
                  </p>
                  <input
                    type="checkbox"
                    id="vehicle12"
                    name="vehicle12"
                    className="form-check-input m-2"
                    defaultValue="Bike1"
                  />
                  <label htmlFor="vehicle12" className="m-2 h6">
                    {" "}
                    I've verified my server time matches the wall clock.
                  </label>
                  <br />
                  <ul className="d-flex mt-2">
                    <li>
                      <i
                        className="bi bi-circle-fill m-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      <p className="h6">
                        Note: the RTC (Real-Time Clock) time may be set to your
                        local timezone instead of UTC, especially in a VM which
                        has its clock configured on Windows
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="rounded-6 mt-5 p-3 contact-form style1"
              style={{
                // backgroundColor: "rgb(255 172 64 / 57%)",
                backgroundColor: "#f39f1b",
                border: "1px solid #ffffff",
              }}
            >
              <div className="col-12">
                <h5 className="h6 text-black rajdhani-600 mt-3">
                  Testnet practice
                </h5>
                <p className="h6">
                  We strongly recommended you complete these steps on the
                  current testnet before Mainnet.{" "}
                 
                </p>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Configure your execution client</h5>
                  <hr />
                  <p className=" text-blue font-md">
                    <a href="#">Review validator roles and responsibilities</a>
                  </p>
                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/geth.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Geth</h2>
                            <p className="text-white">
                              Geth is one of the three original implementations
                              of the Network1 protocol, written in Go.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md">
                              Configure Geth
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/nethermind.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Nethermind</h2>
                            <p className="text-white">
                              Nethermind is a robust client built on .NET core
                              designed for performance, versatility and
                              customizability.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md">
                              Configure Nethermind
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/besu.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Geth</h2>
                            <p className="text-white">
                              Hyperledger Besu is an open-source developed under
                              the Apache 2.0 license and written in Java.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md">
                              Configure besu
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-5">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/erigon.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Erigon</h2>
                            <p className="text-white">
                              Erigon is an execution client on the efficiency
                              frontier, written in Go.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md pt-4">
                              Configure Erigon
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="rounded-6 mt-5 p-3 contact-form style1 mb-3"
                    style={{
                      // backgroundColor: "rgb(255 172 64 / 57%)",
                      backgroundColor: "#f39f1b",
                      border: "1px solid #ffffff",
                    }}
                  >
                    <div className="col-12">
                      <h5 className="h6 text-black rajdhani-600 mt-3">
                        Remember
                      </h5>
                      <p className="h6">
                        All stakers must operate an execution client with their
                        consensus client.{" "}
                      </p>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    id="vehicl"
                    name="vehicl"
                    className="form-check-input m-2"
                    defaultValue="Bik"
                  />
                  <label htmlFor="vehicl" className="m-2 h6">
                    I've installed and synced my Mainnet execution client (do
                    not wait on this as it can take several days).
                  </label>
                  <br />
                  <h5 className="h6 text-white rajdhani-600 mt-3">
                    Recommended
                  </h5>
                  <input
                    type="checkbox"
                    id="vehi"
                    name="vehicl1"
                    className="form-check-input m-2"
                    defaultValue="Bi"
                  />
                  <label htmlFor="vehi" className="m-2 h6">
                    I've joined my execution client's Discord server.
                  </label>
                  <br />
                  <p className="font-md mt-3">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Discord:
                    </span>
                    <span>
                      <a href="#">
                        Geth
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nethermind
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Besu
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Erigon
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Configure your consensus client</h5>
                  <hr />

                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/prysm.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Prysm</h2>
                            <p className="text-white">
                              Prysm is a Go consensus client implementation of
                              the Network1 protocol with a focus on usability,
                              security, and reliability.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md">
                              Configure Prysm
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/teku.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Teku</h2>
                            <p className="text-white">
                              PegaSys Teku is a Java-based Network1 consensus
                              client built to meet institutional needs and
                              security requirements.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md">
                              Configure Teku
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/lighthouse.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Lighthouse</h2>
                            <p className="text-white">
                              Lighthouse is a consensus client implementation,
                              written in Rust with a heavy focus on speed and
                              security.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md mt-4 pt-3">
                              Configure Lighthouse
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-5">
                      <a href="#">
                        <div className="nft-div border dark-bg3 rounded-10 dark-border  mt-4 bg-black">
                          <div className="d-flex p-1 posr">
                            <img
                              className="mb-0 w-100 rounded-10 h-150 "
                              src="assets/images/client/nimbus.png"
                              alt=""
                            />
                          </div>
                          <div className=" p-3">
                            <h2 className="  dark-text h5">Nimbus</h2>
                            <p className="text-white">
                              Nimbus is a research project and a consensus for
                              Network1 designed to perform well on embedded
                              systems and personal mobile devices.
                            </p>
                            <h2 className="rajdhani-600 text-gray-900 dark-text font-md ">
                              Configure Nimbus
                            </h2>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="rounded-6 mt-5 p-3 contact-form style1 mb-3"
                    style={{
                      // backgroundColor: "rgb(255 172 64 / 57%)",
                      backgroundColor: "#f39f1b",
                      border: "1px solid #ffffff",
                    }}
                  >
                    <div className="col-12">
                      <h5 className="h6 text-black rajdhani-600 mt-3">
                        Warning!
                      </h5>
                      <p className="h6">
                        It is high risk to run your validator in multiple
                        places. It will lead to a slashable event and ejection
                        from the network. <br />{" "}
                        <a href="#" className="font-md">
                          More on slashing risks
                        </a>
                      </p>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    id="c1"
                    name="vehicl"
                    className="form-check-input m-2"
                    defaultValue="c1"
                  />
                  <label htmlFor="c1" className="m-2 h6">
                    I've installed the latest stable software release of my
                    consensus client.
                  </label>
                  <br />
                  <h5 className="h6 text-white rajdhani-600 mt-3">
                    Recommended
                  </h5>
                  <input
                    type="checkbox"
                    id="c2"
                    name="vehicl1"
                    className="form-check-input m-2"
                    defaultValue="c2"
                  />
                  <label htmlFor="c2" className="m-2 h6">
                    I've joined my consensus client's Discord server.
                  </label>
                  <br />
                  <p className="font-md mt-3">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Discord:
                    </span>
                    <span>
                      <a href="#">
                        Prysm
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Teku
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Lightouse
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nimbus
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">JWT Authentication</h5>
                  <hr />
                  <p className="h6">
                    Communication between the execution layer and consensus
                    layer occurs using the{" "}
                    <a href="#">
                      Engine Api
                      <i className="bi bi-arrow-up-right m-1" />
                    </a>
                    This is a set of JSON RPC methods that can be used to
                    communicate between the two client layers.
                  </p>
                  <p className="h6 pt-2">
                    This communication is secured using a{" "}
                    <a href="#">
                      {" "}
                      JWT
                      <i className="bi bi-arrow-up-right m-1" />
                    </a>{" "}
                    secret, which is a secret key that is shared only between
                    the two clients to authenticate one another. This shared JWT
                    secret must be made available to each client (both execution
                    and consensus clients) to allow them to communicate with one
                    another properly.
                  </p>
                  <p className="font-md mt-3">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Consensus JWT docs:
                    </span>
                    <span>
                      <a href="#">
                        Lightouse
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nimbus
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Prsm
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Teku
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                  <p className="font-md mt-3">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Execution JWT docs:{" "}
                    </span>
                    <span>
                      <a href="#">
                        Besu
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Erigon
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Geth
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nethermind
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                  <input
                    type="checkbox"
                    id="c3"
                    name="vehicl1"
                    className="form-check-input m-2"
                    defaultValue="c3"
                  />
                  <label htmlFor="c3" className="m-2 h6">
                    I've set up a shared JWT secret and made it available to
                    both my execution client, and my consensus client (beacon
                    node)
                  </label>
                  <br />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Set withdrawal address</h5>
                  <hr />
                  <p className="h6">
                    Stakers must set a withdrawal address to unlock reward
                    payments from the consensus layer. This is set when
                    generating your validator keys.
                  </p>

                  <div
                    className="rounded-6 mt-5 p-3 contact-form style1 mb-3"
                    style={{
                      // backgroundColor: "rgb(255 172 64 / 57%)",
                      backgroundColor: "#f39f1b",
                      border: "1px solid #ffffff",
                    }}
                  >
                    <div className="col-12">
                      <p className="h6">
                        If you do not provide a withdrawal address prior to
                        depositing, you will have to perform an additional step
                        to update your keys and enable withdrawals. Funds will
                        be locked in the meantime.
                      </p>
                    </div>
                  </div>
                  <a href="#" className="h6 text-blue">
                    More on staking withdrawals
                  </a>
                  <br />
                  <ul className="d-flex">
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        id="c4"
                        name="c4"
                        className="form-check-input m-2 mt-3"
                        defaultValue="c4"
                      />
                    </li>
                    <li>
                      <label htmlFor="c4" className="m-2 h6">
                        I provided an Network1 address when generating file
                        before depositing where I would like all validator
                        rewards and withdrawals to be deposited into. If not,
                        I've submitted a message signed with my BLS withdrawal
                        keys to update my withdrawal credentials.
                      </label>
                      <br />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Set fee recipient</h5>
                  <hr />
                  <p className="h6">
                    Stakers must provide a fee recipient address to their
                    consensus client in order to receive transaction fee
                    rewards. This is a normal Network1 address that you're used
                    to.
                  </p>

                  <div
                    className="rounded-6 mt-3 p-3 contact-form style1 mb-3"
                    style={{
                      // backgroundColor: "rgb(255 172 64 / 57%)",
                      backgroundColor: "#f39f1b",
                      border: "1px solid #ffffff",
                    }}
                  >
                    <div className="col-12">
                      <p className="h6">
                        If you do not provide an address to your client, you
                        will not receive transaction fees when your validator
                        proposes blocks.
                      </p>
                    </div>
                  </div>
                  <p className="h6">
                    See your consensus client documentation for client-specific
                    instructions on how to set this.
                  </p>
                  <p className="font-md">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Fee recipient docs:
                    </span>
                    <span>
                      <a href="#">
                        Lightouse
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nimbus
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Prsm
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Teku
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                  <input
                    type="checkbox"
                    id="c6"
                    name="c6"
                    className="form-check-input m-2 "
                    defaultValue="c6"
                  />
                  <label htmlFor="c6" className="m-2 h6">
                    I've provided an Network1 address to my validator where I
                    would like my fee rewards to be deposited.
                  </label>
                  <br />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Consensus Layer Beacon Node (BN)</h5>
                  <hr />
                  <p className="h6  rajdhani-600">Required</p>
                  <input
                    type="checkbox"
                    id="c7"
                    name="c7"
                    className="form-check-input m-2 "
                    defaultValue="c7"
                  />
                  <label htmlFor="c7" className="m-2 h6">
                    I'm able to connect my consensus client to my execution
                    client via HTTP API(s).
                  </label>
                  <br />
                  <br />
                  <p className="h6">
                    {" "}
                    <i
                      className="bi bi-circle-fill mx-2"
                      style={{ fontSize: "8px" }}
                    />
                    Verify it with the following command to check if it returns
                    the client version correctly:
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-11">
                      <div
                        className="rounded-6 mt-2 p-4  contact-form style1 "
                        // style={{ backgroundColor: "rgb(89, 126, 163)" }}
                        style={{backgroundColor:"#030e4e",
                          border: "1px solid #ffffff",}}
                      >
                        <h5 className=" h6 text-white rajdhani-600 lh-1 m-0">
                          curl -H "Content-Type: application/json" -X POST
                          --data '{"{"}"jsonrpc":"2.0", "method":
                          "web3_clientVersion" , "params":{"[]"},"id :" , 67
                          {"}"}
                          http://{"<YourServerLocation>"}:8545
                        </h5>
                      </div>
                    </div>
                  </div>
                  <br />
                  <input
                    type="checkbox"
                    id="c8"
                    name="c8"
                    className="form-check-input m-2 "
                    defaultValue="c8"
                  />
                  <label htmlFor="c8" className="m-2 h6">
                    I'm able to connect my consensus client to my execution
                    client via HTTP API(s).
                  </label>
                  <br />
                  <br />
                  <p className="h6">
                    {" "}
                    <i
                      className="bi bi-circle-fill mx-2"
                      style={{ fontSize: "8px" }}
                    />
                    Make sure that your node has more than 20 peers.
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">
                    Consensus Layer Validator Client (VC)
                  </h5>
                  <hr />
                  <p className="h6  rajdhani-600">Required</p>
                  <input
                    type="checkbox"
                    id="c9"
                    name="c9"
                    className="form-check-input m-2 "
                    defaultValue="c9"
                  />
                  <label htmlFor="c9" className="m-2 h6">
                    I've imported my keystore(s) into my validator client.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c10"
                    name="c10"
                    className="form-check-input m-2 "
                    defaultValue="c10"
                  />
                  <label htmlFor="c10" className="m-2 h6">
                    I've ensured my keystore(s) is/are only stored on one
                    validator machine
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c11"
                    name="c11"
                    className="form-check-input m-2 "
                    defaultValue="c11"
                  />
                  <label htmlFor="c11" className="m-2 h6">
                    I've started running my validator client.
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          {/* section 2 end */}
          {/* section 3 end */}
          <div>
            <h5 className="h5 text-white rajdhani-600 mt-5 " id="Section-three">
              Section 3 - After depositing
            </h5>
            <p className="h6 ">
              Protect your funds using monitoring software, and learn how to
              handle different real world scenarios.
            </p>
            <div
              className="rounded-6 mt-3 p-3 contact-form style1 mb-3 bg-black"
              // style={{
              //   backgroundColor: "rgb(217, 237, 247)",
              //   border: "1px solid rgb(188, 232, 241)",
              // }}
            >
              <div className="col-12 p-2">
                <p className="h6 rajdhani-600">
                  These steps are optional but are recommended to optimize your
                  node.
                </p>
              </div>
            </div>
            <br />
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">
                    Consensus Layer Validator Client (VC)
                  </h5>
                  <hr />
                  <p className="h6  rajdhani-600">
                    Prometheus and Grafana monitor
                  </p>
                  <p className="h6">
                    The clients support Prometheus and Grafana to help you
                    visualize important real-time metrics about your validator.
                  </p>
                  <p className="font-md">
                    <span className="rajdhani-600 h6 text-white me-2">
                      Monitoring docs:
                    </span>
                    <span>
                      <a href="#">
                        Lightouse
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Nimbus
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Prsm
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                    <span>
                      <a href="#">
                        Teku
                        <i className="bi bi-arrow-up-right m-1" />
                      </a>
                    </span>
                    <span className="h4 mx-2">|</span>
                  </p>
                  <input
                    type="checkbox"
                    id="c12"
                    name="c12"
                    className="form-check-input m-2 "
                    defaultValue="c12"
                  />
                  <label htmlFor="c12" className="m-2 h6">
                    I've set up my{" "}
                    <a href="#" className="text-blue">
                      Prometheus<i class="bi bi-arrow-up-right m-1"></i>
                    </a>{" "}
                    service.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c13"
                    name="c13"
                    className="form-check-input m-2 "
                    defaultValue="c13"
                  />
                  <label htmlFor="c13" className="m-2 h6">
                    I've set up my{" "}
                    <a href="#" className="text-blue">
                      Grafana<i class="bi bi-arrow-up-right m-1"></i>
                    </a>{" "}
                    service.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c14"
                    name="c14"
                    className="form-check-input m-2 "
                    defaultValue="c14"
                  />
                  <label htmlFor="c14" className="m-2 h6">
                    I've imported the dashboard config to my Grafana server and
                    double checked that my node is alive.
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Testnet simulations</h5>
                  <hr />
                  <p className="h6">
                    While validating on the testnet, perform these simulations
                    to learn more about your node, and better prepare yourself
                    for Mainnet:
                  </p>
                  <input
                    type="checkbox"
                    id="c15"
                    name="c15"
                    className="form-check-input m-2 "
                    defaultValue="c15"
                  />
                  <label htmlFor="c15" className="m-2 h6">
                    I've simulated how to manually stop and restart my Beacon
                    Node (BN) and Validator Client (VC) gracefully.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c16"
                    name="c16"
                    className="form-check-input m-2 "
                    defaultValue="c16"
                  />
                  <label htmlFor="c16" className="m-2 h6">
                    I've simulated power loss (server and internet) and
                    automatic resumption.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c17"
                    name="c17"
                    className="form-check-input m-2 "
                    defaultValue="c17"
                  />
                  <label htmlFor="c17" className="m-2 h6">
                    I've simulated how to safely migrate from one consensus
                    client to another.
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    id="c18"
                    name="c18"
                    className="form-check-input m-2 "
                    defaultValue="c18"
                  />
                  <label htmlFor="c18" className="m-2 h6">
                    I've simulated how to safely migrate from one execution
                    client to another.
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Advanced system architecture</h5>
                  <hr />
                  <ul className="d-flex">
                    <li>
                      <i
                        class="bi bi-circle-fill mx-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      {" "}
                      <p className="h6">
                        To avoid exposing your validator identity to the
                        network, you can use a trustworthy VPN to help reduce
                        the risk of revealing your IP address.
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex">
                    <li>
                      <i
                        class="bi bi-circle-fill mx-2"
                        style={{ fontSize: "8px" }}
                      />
                    </li>
                    <li>
                      {" "}
                      <p className="h6">
                        Moreover, you can set your Validator Client (VC) and
                        Beacon Node (BN) on separate machines and IPs so that
                        even if your beacon node is vulnerable, your keystore is
                        stored on a different machine.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className=" rounded-10 bg-black p-4 pt-0 mt-3">
                  <h5 className="h5 pt-4">Graffiti</h5>
                  <hr />
                  <p className="h6">
                    You can use your validator client's graffiti flag to add a
                    personal touch to your proposed blocks (some text of your
                    choice). You will be able to see it using{" "}
                    <a href="#" className="text-blue">
                      Network1 <i className="bi bi-arrow-up-right m-1" />
                    </a>
                     explorer.
                  </p>
                  <input
                    type="checkbox"
                    id="c19"
                    name="c19"
                    className="form-check-input m-2 "
                    defaultValue="c19"
                  />
                  <label htmlFor="c19" className="m-2 h6">
                    I've set my graffiti flag.
                  </label>
                </div>
              </div>
            </div>
            <br />
            {/*  */}
            <div className="row ">
              <div className="col-12 p-2">
                <div className="rounded-6 mt-3 p-3 contact-form style1 mb-3 fugu--btn bg-gradient w-100">
                  <p className="h6">
                    If you have questions, EthStaker community is a good place
                    to get help! You can find support on{" "}
                    <a href="#" className="text-blue">
                      Discord <i className="bi bi-arrow-up-right m-1" />
                    </a>{" "}
                    or{" "}
                    <a href="#" className="text-blue">
                      Reddit <i className="bi bi-arrow-up-right m-1" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* section 3 end */}

          {/*  */}
        </div>
        <Footer />
      </div>
    </>
  );
}
