import React from "react";

export default function BecomeValidator() {
  return (
    <>
      <div className="nft-wrapper pt-75 pb-5 bg-gray-200">
        <div className="container pb-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center d-flex justify-content-start gap-2 pb-3">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                Become a validator
              </h2>
            </div>
            <div className="col-lg-12 text-center d-flex justify-content-start gap-2 pb-75">
              <p className="h6" style={{ color: "white" }}>
                Becoming a validator is a big responsibility with important
                preparation steps. Only start the deposit process when you're
                ready.
              </p>
            </div>
          </div>
          {/* <div className="row ">
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column " >
       <div className="col-lg-10">
       <div className="justify-content-center">
         <img src="assets/images/zap/learn.png"  width={100} alt="" />
         </div>

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Learn about your responsibilities
              </h6>
              <p
                className=" dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                The Network1 upgrades will only be successful if validators
                understand the risks and responsibilities.
              </p>
              <a href="">Validators FAQ</a>
       </div>
            </div>
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column ">
              <img src="assets/images/zap/prep.png" width={100} alt="" />

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Prep nodes
              </h6>
              <p
                className=" dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                You'll need to run an execution client (formerly 'NET1') as well
                as a consensus client (formerly 'NET1') to become a validator.
                Take a look at the checklist to prepare yourself and your
                equipment..
              </p>
              <a href="">Hardware checklist</a>
            </div>
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column ">
              <img src="assets/images/zap/practice.png" width={100} alt="" />

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Practice on a testnet
              </h6>
              <p
                className="dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                We strongly recommend you go through the entire process on a
                testnet first to get comfortable before risking real NET1.
              </p>
              <a href="">Try the testnet</a>
            </div>
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column ">
              <img src="assets/images/zap/avoid.png" width={100} alt="" />

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Avoid phishing
              </h6>
              <p
                className="dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                Make sure you're aware of how to avoid phishing attacks. We've
                prepared a list of things to look out for.
              </p>
              <a href="">Phishing guide</a>
            </div>
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column ">
              <img src="assets/images/zap/time.png" width={100} alt="" />

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Time to deposit
              </h6>
              <p
                className="dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                Once you're comfortable, you'll go through generating your keys
                and depositing your NET1.
              </p>
              <a href="">Start deposit process</a>
            </div>
            <div className="col-lg-4 mb-5 md-mb-3 d-flex flex-column justify-content-start text-start">
              <img src="assets/images/zap/wait.png" width={100} alt="" />

              <h6
                className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text"
                style={{ textAlign: "center" }}
              >
                Wait to become active
              </h6>
              <p
                className=" dark-text rajdhani-500 font-sm pe-lg-4 lh-26 mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                Once set up, your validator won't become active straight away.
                Use this time to complete the checklist and get some extra
                practice on a testnet.
              </p>
              <a href="">Complete checklist</a>
            </div>
          </div> */}
          <div className="row mt-5">
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black dark-bg3 rounded-10 gap-2" style={{height:"450px"}}> 
                <div className="d-flex flex-column">
                  <div className="justify-content-center" >
                    <div className="" style={{ textAlign: "center" }}>
                      <img
                        src="assets/images/zap/learn.png"
                        width={100}
                        alt=""
                      />

                      <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                        Learn about your responsibilities
                      </h6>
                      <p
                        className=" dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                        style={{ color: "white" ,}}
                      >
                        The Network1 upgrades will only be successful if
                        validators understand the risks and responsibilities.
                      </p>
                      <br />
                      <a href="">Validators FAQ</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2" style={{height:"450px"}}>
                <div className="d-flex flex-column">
                  <div className="" style={{ textAlign: "center" }}>
                    <img src="assets/images/zap/prep.png" width={100} alt="" />

                    <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                      Prep nodes
                    </h6>
                    <p
                      className=" dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                      style={{ color: "white" }}
                    >
                      You'll need to run an execution client (formerly 'NET1')
                      as well as a consensus client (formerly 'NET1') to become
                      a validator. Take a look at the checklist to prepare
                      yourself and your equipment..
                    </p>
                    <br />
                    <a href="">Hardware checklist</a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2" style={{height:"450px"}}>
                <div className="d-flex flex-column">
                  <div className="" style={{ textAlign: "center" }}>
                    <img
                      src="assets/images/zap/practice.png"
                      width={100}
                      alt=""
                    />

                    <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                      Practice on a testnet
                    </h6>
                    <p
                      className="dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                      style={{ color: "white" }}
                    >
                      We strongly recommend you go through the entire process on
                      a testnet first to get comfortable before risking real
                      NET1.
                    </p>
                    <br />
                    <a href="">Try the testnet</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black dark-bg3 rounded-10 gap-2" style={{height:"400px"}}>
                <div className="d-flex flex-column">
                  <div className="" style={{ textAlign: "center" }}>
                    <img src="assets/images/zap/avoid.png" width={100} alt="" />

                    <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                      Avoid phishing
                    </h6>
                    <p
                      className="dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                      style={{ color: "white" }}
                    >
                      Make sure you're aware of how to avoid phishing attacks.
                      We've prepared a list of things to look out for.
                    </p>
                    <br />
                    <a href="">Phishing guide</a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2" style={{height:"400px"}}>
                <div className="d-flex flex-column">
                  <div className="" style={{ textAlign: "center" }}>
                    <img src="assets/images/zap/time.png" width={100} alt="" />

                    <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                      Time to deposit
                    </h6>
                    <p
                      className="dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                      style={{ color: "white" }}
                    >
                      Once you're comfortable, you'll go through generating your
                      keys and depositing your NET1.
                    </p>
                    <br />
                    <a href="">Start deposit process</a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-4 mb-3">
              <div className="d-flex py-5 px-4 bg-black  dark-bg3 rounded-10 gap-2" style={{height:"400px"}}>
                <div className="d-flex flex-column">
                  <div className="" style={{ textAlign: "center" }}>
                    <img src="assets/images/zap/learn.png" width={100} alt="" />
                    <h6 className="rajdhani-600 mt-3 mb-2 font-lg text-gray-900 dark-text">
                      Wait to become active
                    </h6>
                    <p
                      className=" dark-text rajdhani-500 font-sm pt-2 lh-26 mb-0"
                      style={{ color: "white" }}
                    >
                      Once set up, your validator won't become active straight
                      away. Use this time to complete the checklist and get some
                      extra practice on a testnet.
                    </p>
                    <br />
                    <a href="">Complete checklist</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-3">
            {/* <div className='col-12 text-center'>
                            <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase">BECOME A VALIDATOR</a>
                        </div> */}
            <div className="fugu--btn-wrap  fugu--hero-btn text-center">
              <a
                className="fugu--btn bg-gradient"
                href=""
                style={{ color: "white" }}
              >
                BECOME A VALIDATOR
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
