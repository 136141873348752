import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import Hero from "./Hero";
import BeconChain from "./BeconChain";
import Validators from "./Validators";
import BecomeValidator from "./BecomeValidator";
import Scaling from "./Scaling";

export default function Index() {
  return (
    <>
      <div className="main-wrapper dark-bg3">
        <Header />
        <Hero />
        <BeconChain />
        <Validators />
        <BecomeValidator />
        <Scaling />
        <Footer />
      </div>
    </>
  );
}
