import React from "react";

export default function Validators() {
  return (
    <>
      <div className="faq-wrapper pb-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex">
              <h7
                className="text-dark-900 dark-text  m-0 rajdhani-600 h1"
                style={{ color: "black" }}
              >
                Validators and Network1
              </h7><br/>
            </div>
            <div className="col-12">
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  This launchpad will help you become a validator, so you can
                  play an active part in Network1's future. Validators are key
                  to the more secure, scalable, and sustainable Network1 we're
                  building together.
                </p>
              </div>
              <a href="">
              <span
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on the Network1 vision
                <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" />
              </span>
              </a>
              
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  By running a validator, you'll be responsible for securing the
                  network and receive continuous payouts for actions that help
                  the network reach consensus.
                </p>
              </div>
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  Since the successful transition to proof-of-stake via The
                  Merge, Network1 is fully secured by proof-of-stake validators.
                  By running a validator, you'll be helping to secure the
                  Network1 network.
                </p>
              </div>
              <a href=""
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on the Network1 Chain
                {/* <i className="bi-arrow-up-right text-black-900 dark-text ms-auto h6" /> */}
              </a>
              <br />
              <a href="/"
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on the Merge
                {/* <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" /> */}
              </a>
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  Validating in Network1 is not the same as mining. The outcomes
                  are similar: the work you do will extend and secure the chain.
                  But the process is completely different because they use
                  different consensus mechanisms.
                </p>
              </div>
              <a href=""
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on consensus mechanisms
                <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
