import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

function PrivacyPolicy() {
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          background: "black",
        }}
      >
        <Header />

        <div className="container  mb-5 mt-120">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Privacy Policy
          </h2>
          <br />
          <p className="h6">Last updated: August, 2024</p>
          <hr />
          <p className="h6">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="h6">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h5 className="h6 pt-4 rajdhani-700">
            Interpretation and Definitions
          </h5>
          <h8 className="h6 pt-4 rajdhani-700">Interpretation</h8>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h8 className="h6 pt-4 rajdhani-700">Definitions</h8>
          <p className="h6">For the purposes of this Privacy Policy:</p>
          <div className="mx-4">
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Account : </span>
              means a unique account created for You to access our Service or
              parts of our Service.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Company: </span>
              (referred to as either "the Company", "We", "Us" or "Our" in this
              Agreement) refers to Network1.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Cookies: </span>
              are small files that are placed on Your computer, mobile device or
              any other device by a website, containing the details of Your
              browsing history on that website among its many uses.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Device: </span>
              means any device that can access the Service such as a computer, a
              cellphone or a digital tablet
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Personal Data: </span>
              is any information that relates to an identified or identifiable
              individual. Service refers to the Website.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Service Provider: </span>
              means any natural or legal person who processes the data on behalf
              of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the
              Service on behalf of the Company, to perform services related to
              the Service or to assist the Company in analyzing how the Service
              is used.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Usage Data : </span>
              refers to data collected automatically, either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Website: </span>
              refers to Network1 website.
            </p>
            <p className="fs-6 text-white">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> You: </span>
              means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </p>
            {/* <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Major penalities—or{" "}
              <span className="rajdhani-700"> slashings </span>—are given for
              malicious actions
            </p> */}
          </div>
          {/*  */}
          <h4 className="h6 pt-4 rajdhani-700">
            Collecting and Using Your Personal Data
          </h4>
          <h7 className="h6 pt-4 rajdhani-700">Types of Data Collected</h7>
          <h7 className="h6 pt-4 rajdhani-700">Personal Data</h7>
          <p className="h6">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <h7 className="h6 pt-4 rajdhani-700">Usage Data</h7>
          <p className="h6">
            Usage Data is collected automatically when using the Service.
          </p>
          <p
            className="h6 "
          >
            <p className="h6 ">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p className="h6 ">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data. We may also
              collect information that Your browser sends whenever You visit our
              Service or when You access the Service by or through a mobile
              device.
            </p>
          </p>
          <h7 className="h6 pt-4 rajdhani-700">
            Tracking Technologies and Cookies
          </h7>
          <p
            className="h6"
          >
            <p className="h6">
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6">
                Cookies or Browser Cookies.:
              </span>
              A cookie is a small file placed on Your Device. You can instruct
              Your browser to refuse all Cookies or to indicate when a Cookie is
              being sent. However, if You do not accept Cookies, You may not be
              able to use some parts of our Service. Unless you have adjusted
              Your browser setting so that it will refuse Cookies, our Service
              may use Cookies.
            </p>
            <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              <span className=" rajdhani-700 h6"> Web Beacons.: </span>
              Certain sections of our Service and our emails may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit the Company,
              for example, to count users who have visited those pages or opened
              an email and for other related website statistics (for example,
              recording the popularity of a certain section and verifying system
              and server integrity).
            </p>
          </p>
          <p className="h6 pt-2">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser.
          </p>
          <br />
          <p className="h6 pt-2">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="mx-3 d-flex">
            <li>
              <span className="h6 rajdhani-600 mx-2">1. </span>
            </li>
            <li>
              <p className="h6">
                <span className="rajdhani-600">
                  {" "}
                  Necessary / Essential Cookies
                </span>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Type: Session Cookies
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Administered by: Us
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </p>
            </li>
          </ul>
          <ul className="mx-3 d-flex">
            <li>
              <span className="h6 rajdhani-600 mx-2">2. </span>
            </li>
            <li>
              <p className="h6">
                <span className="rajdhani-600">
                  Cookies Policy / Notice Acceptance Cookies
                </span>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Type: Persistent Cookies
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Administered by: Us
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </p>
            </li>
          </ul>
          <ul className="mx-3 d-flex">
            <li>
              <span className="h6 rajdhani-600 mx-2">3. </span>
            </li>
            <li>
              <p className="h6">
                <span className="rajdhani-600">Functionality Cookies</span>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Type: Persistent Cookies
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Administered by: Us
                </p>
                <p className="h6 rajdhani-600">
                  <i
                    className="bi bi-circle-fill m-2"
                    style={{ fontSize: "8px" }}
                  />
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </p>
            </li>
          </ul>
          <p className="h6 pt-2">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <br />
          <hr/>
          <h4 className="h6 pt-4 rajdhani-700">Use of Your Personal Data</h4>
          <p 
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
          <p className="h6">
            The Company may use Personal Data for the following purposes:
          </p>
          <p className="h6 rajdhani-600">
            <i className="bi bi-circle-fill m-2" style={{ fontSize: "8px" }} />
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </p>
          <p className="h6 rajdhani-600">
            <i className="bi bi-circle-fill m-2" style={{ fontSize: "8px" }} />
            To manage Your requests: To attend and manage Your requests to Us.
          </p>
          </p>
          <h4 className="h6 pt-4 rajdhani-700">
            Retention of Your Personal Data
          </h4>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </p>
          <br />
          <h4 className="h6 pt-4 rajdhani-700">
            Transfer of Your Personal Data
          </h4>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            <p className="h6 pt-2">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p className="h6 pt-2">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p className="h6 pt-2">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
          </p>
          <br />
          <h4 className="h6 pt-4 rajdhani-700">Delete Your Personal Data</h4>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            <p className="h6 pt-2">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </p>
            <p className="h6 pt-2">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </p>
            <p className="h6 pt-2">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </p>
          </p>
          <p className="h6 pt-2">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <br />
          <hr />
          <h4 className="h6 pt-4 rajdhani-700">
            Disclosure of Your Personal Data
          </h4>
          <br />
          <h7 className="h6 pt-4 rajdhani-700">Law enforcement</h7>
          <p className="h6 pt-2">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h7 className="h6 pt-4 rajdhani-700">Other legal requirements</h7>
          <p className="h6">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <div className="mx-4">
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Comply with a legal obligation
            </p>
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Protect and defend the rights or property of the Company
            </p>
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </p>
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Protect the personal safety of Users of the Service or the public
            </p>
            <p className="fs-6 text-white ">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Protect against legal liability
            </p>
          </div>
          <h7 className="h6 pt-4 rajdhani-700">
            Security of Your Personal Data
          </h7>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <br />
          <h5 className="h6 pt-4 rajdhani-700">Links to Other Websites</h5>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            <p className="h6">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p className="h6">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </p>
          <br />
          <h5 className="h6 pt-4 rajdhani-700">
            Changes to this Privacy Policy
          </h5>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            <p className="h6">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="h6">
              99 We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </p>
          <br /> <hr />
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">Contact Us</h2>
          <br />
          <p className="h6">
            If you have any questions about this Privacy Policy, You can contact
            us99:<a href="mailto:info@network1.tech "> info@network1.tech </a>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
