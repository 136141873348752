import React from "react";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  return (
    <>
      <br />
      <br />
      <div className="container--fluid mt-2">
        <div className="row justify-content-center mt-5">
          <div className="col-12">
            <div className="subscribe-banner py-2 bg-white px-5 d-flex justify-content-center align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${location.pathname === "/overview" ? "active-nav" : ""}`}
                  >
                    Advisories <i class="bi bi-chevron-right"></i>
                  </li>
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${
                      location.pathname === "/select-client" ? "active-nav" : ""
                    }`}
                  >
                    Choose client <i class="bi bi-chevron-right"></i>
                  </li>
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${
                      location.pathname === "/generate-key" ? "active-nav" : ""
                    }`}
                  >
                    Generate keys <i class="bi bi-chevron-right"></i>
                  </li>
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${
                      location.pathname === "/upload-deposit-data" ? "active-nav" : ""
                    }`}
                  >
                    Upload deposit data <i class="bi bi-chevron-right"></i>
                  </li>
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${
                      location.pathname === "/connect-wallet" ? "active-nav" : ""
                    }`}
                  >
                    Connect wallet <i class="bi bi-chevron-right"></i>
                  </li>
                  {/* <li className={`breadcrumb-item fs-5 fw-bold ${location.pathname === "/select-client" ? "active" :""}`}>Summary <i class="bi bi-chevron-right"></i></li> */}
                  <li
                    className={`breadcrumb-item fs-5 fw-bold ${
                      location.pathname === "/transactions" ? "active-nav" : ""
                    }`}
                  >
                    Transactions
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
