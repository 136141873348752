import React, { useContext, useEffect } from "react";
import Header from "../Comman/Header";
import Navbar from "./Navbar";
import Footer from "../Comman/Footer";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { appContext } from "../../AppContext";

export default function Generatekeys() {
  const navigate = useNavigate();
  const {
    validators,
    setValidators,
    withdrawalAddress,
    setWithdrawalAddress,
    securityCheck,
    setSecurityCheck,
    validteReload,
  } = useContext(appContext);
  const [showtab, setShowTab] = useState(0);
  const [showline, setShowLine] = useState(0);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(validators * 10000);
  }, [validators]);

  useEffect(() => {
    validteReload();
  });

  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        }}
        // style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}
      >
        <Header />
        <Navbar />
        <div className="container mt-5 mb-5">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Generate key pairs
          </h2>
          {/*  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-1 mt-3">
                <div class="row ">
                  <div className="col-12 p-5">
                    <h2 className="h4 text-white rajdhani-600 lh-1 m-0 ">
                      How many validators would you like to run?
                    </h2>
                    <div className="row">
                      <div className="col-5">
                        <ul className="d-flex justify-content-between pt-3 h6">
                          <li>Validators</li>
                          <li>Cost</li>
                        </ul>
                        <ul className="d-flex justify-content-between pt-3 h6">
                          <li>
                            <input
                              type="number"
                              className="p-2"
                              value={validators}
                              onChange={(e) => {
                                if (e.target.value.replace(/\D/g, "")) {
                                  console.log(e.target.value);
                                  setValidators(Number(e.target.value));
                                }
                              }}
                            />
                          </li>
                          <li>{value > 0 ? value.toFixed(1) : ""} Cost</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-1 mt-3">
                <div class="row ">
                  <div className="col-11 p-5">
                    <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pb-4 ">
                      Withdrawal address
                    </h2>
                    <p className="h6">
                      You may choose to provide a withdrawal address with your
                      initial deposit to automatically enable reward payments
                      and also the ability to fully exit your funds at anytime
                      (recommended). This address should be to a regular
                      Network1 address and will be the only address funds can be
                      sent to from your new validator accounts, and cannot be
                      changed once chosen.
                    </p>
                    <p className="h6 pt-4">
                      Paste your chosen address here to include it in the
                      copy/paste CLI command below:
                    </p>
                    <input
                      type="text"
                      className="w-100  p-2"
                      placeholder="Ox..."
                      value={withdrawalAddress}
                      onChange={(e) => setWithdrawalAddress(e.target.value)}
                    />
                    <div
                      className="rounded-6 mt-5 p-3 contact-form style1"
                      style={{
                        backgroundColor: "rgba(255, 222, 179, 0.18)",
                        border: "1px solid burlywood",
                      }}
                    >
                      <div className="col-12">
                        <p className="h6 p-1">
                          If this is not provided now, your deposited funds will
                          remain locked on the Network1 Chain until an address
                          is provided. Unlocking will require signing a message
                          with your withdrawal keys, generated from your
                          mnemonic seed phrase (so keep it safe).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/*  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-1 mt-3">
                <div class="row ">
                  <div className="col-12 p-5">
                    <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pb-4 ">
                      What is your current operating system?
                    </h2>
                    <p className="h6">
                      Choose the OS of the computer you're currently using. This
                      will be the computer you use to generate your keys. It
                      doesn't need to be the OS you want to use for your node.
                    </p>
                    {/*  */}
                    <div className="row mt-5 justify-content-center ">
                      <div
                        className="col-lg-3 mb-1 cursor-pointer "
                        onClick={() => setShowLine(0)}
                      >
                        <div
                          className={
                            showline === 0
                              ? "card-body light-bg dark-border border border-dark border-2 active"
                              : "card-body light-bg dark-border border border-dark border-2"
                          }
                        >
                          <div className="row  justify-content-center">
                            <button className="btn  w-110 h-110 alert-success border-0 text-warning rounded-pill mt-3">
                              <img
                                src="assets/images/linux.svg"
                                className="w-110"
                                alt=""
                              />
                            </button>
                            <p className="h4 text-white text-center  rajdhani-600 lh-1 m-0 p-0 mt-4">
                              Linux
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-3 mb-1 cursor-pointer"
                        onClick={() => setShowLine(1)}
                      >
                        <div
                          className={
                            showline === 1
                              ? "card-body light-bg dark-border border border-dark border-2 active"
                              : "card-body light-bg dark-border border border-dark border-2"
                          }
                        >
                          <div className="row  justify-content-center">
                            <button className="btn  btn-xl w-110 h-110 alert-warning border-0 text-warning rounded-pill mt-3">
                              <img
                                src="assets/images/windos.svg"
                                className="w-100"
                                alt=""
                              />
                            </button>
                            <p className="h4 text-white text-center  rajdhani-600 lh-1 m-0 p-0 mt-4">
                              Windows
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-3 mb-1 cursor-pointer"
                        onClick={() => setShowLine(2)}
                      >
                        <div
                          className={
                            showline === 2
                              ? "card-body light-bg dark-border border border-dark border-2 active"
                              : "card-body light-bg dark-border border border-dark border-2"
                          }
                        >
                          <div className="row  justify-content-center">
                            <button className="btn btn-xl w-110 h-110 alert-success border-0 text-warning rounded-pill mt-3">
                              <img
                                src="assets/images/mac.svg"
                                className="w-100"
                                alt=""
                              />
                            </button>
                            <p className="h3 text-white text-center  rajdhani-600 lh-1 m-0 p-0 mt-4">
                              Mac
                            </p>
                            {/* <canvas className="graph" id="sidebarone" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/*4a start  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-1 mt-3">
                <div class="row ">
                  <div className="col-12 p-5">
                    <h2 className="h4 text-white rajdhani-600 lh-1 m-0 ">
                      How do you want to generate your keys?
                    </h2>
                    <div className="row">
                      <div className="col-10 mt-2  portfolio-tab ">
                        <ul className="d-flex justify-content-between nft-wrapper m-3  h6  text-white">
                          <li
                            className={
                              showtab === 0
                                ? "p-0 filter m-2 active"
                                : "p-0 filter m-2"
                            }
                            data-filter=".Download CLI app"
                            onClick={() => setShowTab(0)}
                          >
                            <span style={{ color: "white" }}>
                              Download CLI app
                            </span>
                          </li>
                          <li
                            className={
                              showtab === 1
                                ? "p-0 filter m-2 active"
                                : "p-0 filter m-2"
                            }
                            data-filter=".game"
                            onClick={() => setShowTab(1)}
                          >
                            <span style={{ color: "white" }}>
                              Download Key Gen GUI app
                            </span>
                          </li>
                          <li
                            className={
                              showtab === 2
                                ? "p-0 filter m-2 active"
                                : "p-0 filter m-2"
                            }
                            data-filter=".game"
                            onClick={() => setShowTab(2)}
                          >
                            <span style={{ color: "white" }}>
                              Build from source
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={showtab === 0 ? "" : "d-none"}>
                      <h2 className="h4 text-black rajdhani-600 lh-1 m-0 ">
                        Download command line app
                      </h2>
                      <p className="h6 pt-3 rajdhani-600">
                        Step 1: Download the deposit command line interface app
                        for your operating system
                      </p>
                      <div className="col-4">
                        <a
                          href="#"
                          className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                          style={{ color: "white" }}
                          // className="btn  btn-block text-gray-900 rajdhani-600  bg-success  py-1 font-xl mt-3"
                        >
                          DOWNLOD FROM GITHUB
                          <i class="bi bi-arrow-up-right m-1"></i>
                        </a>
                      </div>
                      <div
                        className="rounded-6 mt-5 p-3 mb-5 contact-form style1"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <div className="col-12">
                          <ul className="d-flex justify-content-between p-3">
                            <li>
                              <i class="bi bi-exclamation-triangle font-xl p-4"></i>
                            </li>
                            <li>
                              <p className="h6">
                                {" "}
                                Please make sure that you are downloading from
                                the official Network1 Foundation GitHub account
                                by verifying the url:{" "}
                                <span className="rajdhani-600 ">
                                  {" "}
                                  https://github.com/Network1/staking-deposit-cli/releases/{" "}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <img
                        src="assets/images/staking-cli.png"
                        className="img-fluid"
                        alt=""
                      />
                      <h2 className="h6 text-white rajdhani-600 lh-1 m-0 ">
                        Step 2: Generate deposit keys using the Network1
                        Foundation deposit tool
                      </h2>
                      <div
                        className="rounded-6 mt-5 p-3  contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                      >
                        <div className="col-12">
                          <p className="h5 p-2" style={{ color: "white" }}>
                            For security, we recommend you disconnect from the
                            internet to complete this step.
                          </p>
                        </div>
                      </div>
                      <div className="p-4">
                        <p className="h6">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Decompress the file you just downloaded
                        </p>
                        <p className="h6">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Use the terminal to move into the directory that
                          contains the deposit executable
                        </p>
                        <p className="h6">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Run the following command to launch the app
                        </p>
                        <div
                          className="rounded-6 mt-5 p-3  contact-form style1 "
                          style={{ border: "1px solid #ffffff" }}
                          // style={{ backgroundColor: "rgb(253, 252, 254)", border: "1px solid rgb(211, 211, 211)" }}
                        >
                          <div className="col-12">
                            <p
                              className={
                                showline === 0 ? "h6 p-2 text-red" : "d-none"
                              }
                            >
                              ./deposit new-mnemonic --chain mainnet
                            </p>
                            <p
                              className={
                                showline === 1 ? "h6 p-2 text-red" : "d-none"
                              }
                            >
                              .\deposit.exe new-mnemonic --chain mainnet
                            </p>
                            <p
                              className={
                                showline === 2 ? "h6 p-2 text-red" : "d-none"
                              }
                            >
                              ./deposit new-mnemonic --chain mainnet
                            </p>
                          </div>
                        </div>
                        <br />
                        <div
                          className="rounded-6 mt-3 p-3  contact-form style1"
                          style={{ border: "1px solid #ffffff" }}
                          // style={{ backgroundColor: "rgb(242, 222, 222)", border: "1px solid rgb(235, 204, 209)" }}
                        >
                          <div className="col-12">
                            <p className="h5 p-3 " style={{ color: "white" }}>
                              Make sure you have set --chain mainnet for
                              Mainnet, otherwise the deposit will be invalid.
                            </p>
                          </div>
                        </div>
                        <p className="h6 mt-4">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Now follow the instructions presented to you in the
                          terminal window to generate your keys.
                        </p>
                      </div>
                    </div>
                    {/* 2b start */}
                    <div className={showtab === 1 ? "" : "d-none"}>
                      <h2 className="h4 text-black rajdhani-600 lh-1 m-0 ">
                        Download Wagyu Key Gen app
                      </h2>
                      <p className="h6 pt-3 rajdhani-600">
                        Step 1: Download the Wagyu Key Gen app for your
                        operating system
                      </p>
                      <div className="col-4">
                        <a
                          href="#"
                          className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                          style={{ color: "white" }}

                          // className="btn  btn-block text-gray-900 rajdhani-600  bg-success  py-1 font-xl mt-3 mb-4"
                        >
                          DOWNLOD FROM GITHUB
                          <i class="bi bi-arrow-up-right m-1"></i>
                        </a>
                        <a href="#" className="text-white h6 ">
                          View Wagyu Key Gen audit by HashCloak{" "}
                          <i class="bi bi-arrow-up-right m-1"></i>
                        </a>
                      </div>
                      <div
                        className="rounded-6 mt-5 p-3 mb-5 contact-form style1"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                      >
                        <div className="col-12">
                          <ul className="d-flex justify-content-between p-3">
                            <li>
                              <i class="bi bi-exclamation-triangle font-xl p-4"></i>
                            </li>
                            <li>
                              <p className="h6">
                                Please make sure that you are downloading from
                                the official StakeHouse GitHub account by
                                verifying the url:{" "}
                                <span className="rajdhani-600 ">
                                  {" "}
                                  https://github.com/stake-house/wagyu-key-gen/releases{" "}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <img
                        src="assets/images/staking-gui.png"
                        className="img-fluid"
                        alt=""
                      />
                      <h2 className="h6 text-white rajdhani-600 lh-1 m-0 ">
                        Step 2: Generate deposit keys using the Wagyu Key Gen
                        app
                      </h2>
                      <div
                        className="rounded-6 mt-5 p-3  contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                      >
                        <div className="col-12">
                          <p className="h5 p-2">
                            For security, we recommend you disconnect from the
                            internet to complete this step..
                          </p>
                        </div>
                      </div>
                      <div className="p-4">
                        <p className="h6">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Make the file you just downloaded executable.
                        </p>
                        <p className="h6">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Launch the app from your desktop environment by double
                          clicking on it.
                        </p>
                        <div
                          className="rounded-6 mt-5 p-3  contact-form style1"
                          style={{
                            backgroundColor: "rgba(255, 222, 179, 0.18)",
                            border: "1px solid burlywood",
                          }}
                          // style={{ backgroundColor: "rgb(242, 222, 222)", border: "1px solid rgb(235, 204, 209)" }}
                        >
                          <div className="col-12">
                            <p className="h6 p-2 ">
                              Please make sure you select Mainnet when prompted
                              for a network, otherwise the deposit will be
                              invalid.
                            </p>
                          </div>
                        </div>
                        <p className="h6 mt-4">
                          <i
                            class="bi bi-circle-fill m-2"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Follow the instructions presented to you in the
                          application to generate your keys.
                        </p>
                      </div>
                    </div>
                    {/* 2b end */}
                    {/* 3b start */}
                    <div className={showtab === 2 ? "" : "d-none"}>
                      <h2 className="h4 text-blue rajdhani-600 lh-1 m-0 ">
                        Build deposit-cli from the Python source code
                      </h2>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        Install python3.7+
                      </p>
                      <p className="h6">
                        The python3 install process may differ depending on your
                        linux build.
                      </p>
                      <p className="h6">
                        If you need help, check out the Python documentation.
                      </p>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        <a href="#" className="text-blue rajdhani-600">
                          python installation instructions
                        </a>
                        <i class="bi bi-arrow-up-right m-1" />{" "}
                      </p>
                      <div
                        className="rounded-6 mt-5 p-3  contact-form style1"
                        style={{
                          backgroundColor: "rgba(255, 222, 179, 0.18)",
                          border: "1px solid burlywood",
                        }}
                        // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                      >
                        <div className="col-12">
                          <p className="h6 p-2">
                            You can check your Python version by typing python3
                            -V in your terminal.
                          </p>
                        </div>
                      </div>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        Install pip3+
                      </p>
                      <p className="h6">
                        You can install pip using a Linux Package Manager like
                        apt or yum .
                      </p>
                      <p className=" h6 pt-3">
                        <a href="#" className="text-blue rajdhani-500">
                          More on install pip{" "}
                          <i class="bi bi-arrow-up-right m-1" />{" "}
                        </a>
                      </p>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        Install virtualenv
                      </p>
                      <p className="h6">
                        virtualenv would help you to create an isolated Python
                        environment for deposit-cli tool{" "}
                        <a href="#" className="text-blue rajdhani-500">
                          More on virtualenv{" "}
                          <i class="bi bi-arrow-up-right m-1" />{" "}
                        </a>
                      </p>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        Install deposit-cli tool
                      </p>
                      <p className="h6">
                        Download and uncompress the master branch source code
                        from GitHub.
                      </p>
                      <div className="row">
                        <div className="col-7">
                          <a
                            href="#"
                            className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                            style={{ color: "white" }}
                            // className="btn  btn-block text-gray-900 rajdhani-600  bg-skype  py-1 font-xl mt-3"
                          >
                            DOWNLOD MASTER BRANCH SOURCE CODE{" "}
                            <i class="bi bi-arrow-up-right m-1"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="rounded-6 mt-5 p-3  contact-form style1 bg-black"
                        // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                      >
                        <div className="col-12">
                          <p className="h6 p-2">
                            If you’re a git user, you can run git clone -b
                            master --single-branch &nbsp;
                            <a href="https://github.com/Network1/staking-deposit-cli.gitto" className="rajdhani-600 ">
                              https://github.com/Network1/staking-deposit-cli.gitto
                            </a>&nbsp;
                            download the master branch.
                          </p>
                        </div>
                      </div>
                      <br />
                      <p className="h6 p-2">
                        First, create a venv virtualenv under repository
                        directory:
                      </p>
                      <div
                        className="rounded-6  p-4 mb-2 contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(253, 252, 254)", border: "1px solid rgb(211, 211, 211)" }}
                      >
                        <div className="col-12">
                          <p className="h6 rajdhani-600">
                            {" "}
                            <span className="text-blue">virtualenv</span> venv
                          </p>
                          <p className="h6 rajdhani-600 ">
                            source venv/bin/activate
                          </p>
                        </div>
                      </div>
                      <br />
                      <p className="h6">
                        Second, install the dependency packages:
                      </p>
                      <div
                        className="rounded-6  p-4 mb-2 contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(253, 252, 254)", border: "1px solid rgb(211, 211, 211)" }}
                      >
                        <div className="col-12">
                          <p className="h6 rajdhani-600 text-instagram">
                            {" "}
                            python3 setup.py install
                          </p>
                          <p className="h6 rajdhani-600 text-instagram ">
                            pip3 install -r requirements.txt
                          </p>
                        </div>
                      </div>
                      <p className="text-blue rajdhani-600 h6 pt-3">
                        Generate deposit keys using the Network1 Foundation
                        deposit tool
                      </p>
                      <div
                        className="rounded-6 mt-2 p-3 mb-2  contact-form style1 "
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                      >
                        <div className="col-12">
                          <p className="h6 p-2 rajdhani-600">
                            For security, we recommend you disconnect from the
                            internet to complete this step.
                          </p>
                        </div>
                      </div>
                      <p className="h6 pt-3">
                        Type the following lines into the terminal window:
                      </p>

                      <div
                        className="rounded-6  p-3   contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(253, 252, 254)", border: "1px solid rgb(211, 211, 211)" }}
                      >
                        <div className="col-12">
                          <p className="h6 p-2">
                            python3 ./staking_deposit/deposit.py new-mnemonic
                            --chain mainnet
                          </p>
                        </div>
                      </div>
                      <div
                        className="rounded-6 mt-3  p-3  contact-form style1"
                        style={{ border: "1px solid #ffffff" }}
                        // style={{ backgroundColor: "rgb(242, 222, 222)", border: "1px solid rgb(235, 204, 209)" }}
                      >
                        <div className="col-12">
                          <p className="h6 p-2">
                            Make sure you have set --chain mainnet for Mainnet,
                            otherwise the deposit will be invalid.
                          </p>
                        </div>
                      </div>
                      <p className="h6 pt-3">
                        Make sure you have set --chain mainnet for Mainnet,
                        otherwise the deposit will be invalid.
                      </p>
                      <p className=" h6 ">
                        <a href="#" className="text-blue rajdhani-500">
                          https://github.com/Network1/staking-deposit-cli{" "}
                          <i class="bi bi-arrow-up-right m-1" />{" "}
                        </a>
                      </p>
                    </div>
                    {/* 3b end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* 4a end*/}
          {/*  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-1 mt-3 p-4">
                <h3 className="text-blue rajdhani-600 h4  pt-3">
                  Save the key files and get the validator file ready
                </h3>
                <p className="h6">
                  You should now have your mnemonic written down in a safe place
                  and a keystore saved for each of your 0 validators. Please
                  make sure you keep these safe, preferably offline. Your
                  validator keystores should be available in the newly created
                  validator_keys directory.
                </p>
                <br />
                <div
                  className="rounded-6 mt-4 p-3 m-3  contact-form style1 bg-black"
                  // style={{ backgroundColor: "rgb(217, 237, 247)", border: "1px solid rgb(188, 232, 241)" }}
                >
                  <div className="col-12">
                    <p className="h6 p-2 rajdhani-600">
                      You should see that you have one keystore per validator.
                      This keystore contains your signing key, encrypted with
                      your password.
                    </p>
                  </div>
                </div>
                <div className="col-12 text-center mt-100 mb-75">
                  <img
                    src="assets/images/save-kay-1.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <p className="h6 p-3">
                  The other file you just generated is deposit_data.json. This
                  file contains the public key(s) associated with your
                  validator(s); You will need to upload this in the next step.
                </p>
                <div className="col-12 text-center mt-100 mb-75">
                  <img
                    src="assets/images/img-one/save-kay-1.svg"
                    className="img-fluid"
                    alt=""
                    style={{ height: "150px", width: "350px" }}
                  />
                </div>
                <div
                  className="rounded-6 mt-3  p-3 mb-2 contact-form style1"
                  style={{
                    backgroundColor: "rgba(255, 222, 179, 0.18)",
                    border: "1px solid burlywood",
                  }}
                  // style={{ backgroundColor: "rgb(242, 222, 222)", border: "1px solid rgb(235, 204, 209)" }}
                >
                  <div className="col-12">
                    <p className="h6 p-2 ">
                      Warning: Do not store keys on multiple (backup) validator
                      clients at once
                    </p>
                    <p className=" h6 ">
                      {/* <a href="#" className="text-blue rajdhani-500">
                        More on slashing prevention
                        <i class="bi bi-arrow-up-right m-1" />{" "}
                      </a> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/*  */}
          {/*  */}
          <div className="row justify-content-center">
            <div className="col-12">
              <div className=" rounded-10 bg-black p-5 mt-3">
                <input
                  type="checkbox"
                  id="securitycheck1"
                  name=""
                  className="m-2 mt-2"
                  checked={securityCheck}
                  onChange={(e) => setSecurityCheck(e.target.checked)}
                />{" "}
                <span htmlFor="securitycheck1" className="h6 m-2">
                  I am keeping my key(s) safe and have written down my mnemonic
                  phrase.
                </span>
              </div>
            </div>
          </div>
          <br />
          {/*  */}
          {/* button */}
          <div className="row justify-content-center">
            <div class="col-md-10">
              <div className="row justify-content-center">
                <div class="col-md-3">
                  <Link
                    to="/select-client"
                    className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                    style={{ color: "white" }}
                    // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                  >
                    Back
                  </Link>
                </div>
                <div class="col-md-3">
                  <button
                    className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3"
                    style={{ color: "white" }}
                    // className="btn btn-lg btn-block text-gray-900 rajdhani-700  bg-current px-5 py-3 font-sm mt-3"
                    onClick={() => navigate("/upload-deposit-data")}
                    disabled={!securityCheck}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
        <Footer />
      </div>
    </>
  );
}
