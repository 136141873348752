import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

export default function Faq() {
  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          background: "black",
          // "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)",
        }}
      >
        <Header />

        <div className="container  mb-5 mt-120">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">
            Validator FAQs
          </h2>
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-4">
            <a href="#" className="text-white">
              Introduction
            </a>
            <hr />
          </h2>
          <h6 className="h6 pt-4 rajdhani-700">What exactly is a validator?</h6>
          <p className="h6">
            A validator client is the software that acts on behalf of the
            validator by holding and using its private key to make attestations
            about the state of the chain. A single validator client can hold
            many key pairs, controlling many validators.{" "}
          </p>
          <h6 className="h6 pt-4 rajdhani-700">What is a validator client?</h6>
          <p className="h6">
            A validator client is the software that acts on behalf of the
            validator by holding and using its private key to make attestations
            about the state of the chain. A single validator client can hold
            many key pairs, controlling many validators.{" "}
          </p>
          <h6 className="h6 pt-4 rajdhani-700">What is a node operator?</h6>
          <p className="h6">
            A node operator is the human being who makes sure the client
            software is running appropriately, maintaining hardware as needed.{" "}
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            How much NET1 do I need to stake to become a validator?
          </h6>
          <p className="h6">
            Each key-pair associated with a validator requires locking 10,000
            NET1 to be activated, which represents your initial balance as well
            as your initial and maximum voting power for any validator.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            Is there any advantage to having more than 10,000 NET1 at stake?{" "}
          </h6>
          <p className="h6">
            No. There is no advantage to having more than 10,000 NET1 staked.{" "}
          </p>
          <p className="h6">
            Depositing more than 10,000 NET1 to a single set of keys does not
            increase rewards potential, nor does accumulating rewards above
            10,000 NET1, as each validator is limited to an effective balance of
            10,000 . This means that staking is done in 10,000 NET1 increments,
            each with its own set of keys and balance.
          </p>
          <p className="h6">
            Validators with a maxed-out effective balance and a linked execution
            withdrawal address will have any balance over 10,000 NET1
            automatically withdrawn as excess balance.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">Why the 10,000 NET1 maximum?</h6>
          <p className="h6">
            Each 10,000 NET1 deposit activates one set of validator keys. These
            keys are used to sign off on the state of the network. The lower the
            NET1 requirement, the more resulting signatures must be saved by the
            network. 10,000 NET1 was chosen as a balance between enabling as
            many people as possible to stake without inhibiting decentralization
            by bloating the size of each block with signatures.
          </p>
          <p className="h6">
            Limiting the maximum stake to 10,000 NET1 per validator encourages
            decentralization of power as it prevents any single validator from
            having an excessively large vote on the state of the chain. It also
            limits the amount of NET1 that can be exited from staking at any
            given time, as the number of validators that can exit in a given
            time period is limited. This helps protect the network against
            certain attacks.
          </p>
          <p className="h6">
            Although a validator's vote is weighted by the amount it has at
            stake, each validator's voting weight starts at, and is capped at
            10,000. It is possible to drop below this with poor node
            performance, but it is not possible to raise above it. Do not
            deposit more than 10,000 NET1 for a single validator—it will not add
            to your rewards.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            What is the deposit contract?
          </h6>
          <p className="h6">
            You can think of the deposit contract as a transfer of funds from an
            Ethereum account to a proof-of-stake validator account. It specifies
            who is staking, who is validating, how much is being staked, and who
            can withdraw the funds.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            Why do I need to have funds at stake?
          </h6>
          <p className="h6">
            Each 10,000 NET1 deposit activates one set of validator keys. These
            keys are used to sign off on the state of the network. The lower the
            NET1 requirement, the more resulting signatures must be saved by the
            network. 10,000 NET1 was chosen as a balance between enabling as
            many people as possible to stake without inhibiting decentralization
            by bloating the size of each block with signatures.
          </p>
          <p className="h6">
            In other words, to keep you honest, your actions need to have
            financial consequences.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            Can my validator node go offline?
          </h6>
          <p className="h6">
            Yes, but with small penalties. If you go offline for a number of
            days under normal conditions you will lose an amount of NET1 roughly
            equivalent to the amount of NET1 you would have gained in that
            period. In other words, if you stood to earn ≈10 NET1, you would
            instead be penalized ≈10 NET1.
          </p>
          <p className="h6">
            Each 10,000 NET1 deposit activates one set of validator keys. These
            keys are used to sign off on the state of the network. The lower the
            NET1 requirement, the more resulting signatures must be saved by the
            network. 10,000 NET1 was chosen as a balance between enabling as
            many people as possible to stake without inhibiting decentralization
            by bloating the size of each block with signatures.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            When should I top up my validator’s balance?
          </h6>
          <p className="h6">
            Each 10,000 NET1 deposit activates one set of validator keys. These
            keys are used to sign off on the state of the network. The lower the
            NET1 requirement, the more resulting signatures must be saved by the
            network. 10,000 NET1 was chosen as a balance between enabling as
            many people as possible to stake without inhibiting decentralization
            by bloating the size of each block with signatures.
          </p>
          <p className="h6">
            You should certainly top up if your balance is close to 5000 NET1.
            This is to ensure you don’t get kicked out of the validator set
            (which automatically happens if your balance falls below 50000
            NET1).
          </p>
          <p className="h6">
            At the other end of the spectrum, if your balance is closer to 9000
            NET1, it’s probably not worth adding the extra NET1 required to get
            back to 10,000.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            When can I withdraw my funds, and what’s the difference between
            exiting and withdrawing?
          </h6>
          <p className="h6">
            You can signal your intent to stop validating by signing a voluntary
            exit message with your validator at any time.
          </p>
          <p className="h6">
            This will start the process of ending your commitments on the
            network. An “exit epoch” will be calculated based on how many other
            validators are exiting at the same time. When this epoch is reached,
            your validator account will no longer be eligible for rewards, and
            will no longer be at risk of losing funds from being offline.
          </p>
          <p className="h6">
            A “withdrawable epoch” will also be calculated, which is 256 epochs
            after the calculated exit epoch (~27.3 hours later). When this epoch
            is reached, your validator will be eligible for a full withdrawal of
            all remaining funds.
          </p>
          <p className="h6">
            This full withdrawal of funds requires that a withdrawal address be
            set, and will be automatically transferred in the next validator
            sweep.
          </p>
          
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 mt-5">
            <a href="" className="text-white">
              Responsibilities
            </a>
            <hr />
          </h2>
          <h6 className="h6 pt-4 rajdhani-700">
            What clients do I need to run?
          </h6>
          <p className="h6">
            As a staker you are required to maintain and operate a node, running
            both a consensus client AND an execution client.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            Why do I need to run an execution client now?
          </h6>
          <p className="h6">
            To sync with the execution on proof-of-network (PoN), validators are
            now responsible for processing transactions and signing off on their
            validity. This data is not available from popular third-party
            sources. Using a third-party provider will result in your validator
            being offline.
          </p>
          {/* <p className="h6">
            With the transition to proof-of-stake complete via The Merge,
            validators are now responsible for processing transactions and
            signing off on their validity. This data is not available from
            popular third-party sources as of The Merge. Using a third-party
            provider will result in your validator being offline. When
            Danksharding is implemented, validators may also be at risk of
            slashing under the proof-of-custody game.
          </p> */}
          <h6 className="h6 pt-4 rajdhani-700">
            How are validators incentivized to stay active and honest?
          </h6>
          <p className="h6">
            As a validator you are rewarded for proposing/attesting to blocks
            that are included in the chain. On the other hand, you can be
            penalized for being offline and behaving maliciously—for example
            attesting to invalid or contradicting blocks.
          </p>
          {/* <p className="h6">
            On the other hand, you can be penalized for being offline and
            behaving maliciously—for example attesting to invalid or
            contradicting blocks.
          </p> */}
          <p className="h6">The key concept is the following:</p>
          {/*  */}
          <div className="mx-4">
            <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Rewards are given for actions that help the network reach
              consensus.
            </p>
            <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Minor penalties are given for inadvertent actions (or inactions)
              that hinder consensus.
            </p>
            <p className="h6 rajdhani-600">
              <i
                className="bi bi-circle-fill m-2"
                style={{ fontSize: "8px" }}
              />
              Major penalities—or{" "}
              <span className="rajdhani-700"> slashings </span>—are given for
              malicious actions
            </p>
          </div>
          {/*  */}
          <p className="h6 pt-2">
            In other words, you maximize your rewards by providing the greatest
            benefit to the network as a whole.
          </p>
          <h6 className="h6 pt-4 rajdhani-700">
            How are rewards/penalties issued?
          </h6>
          <p className="h6">
            Your balance is updated periodically by the Network1 chain rules as
            you carry (or fail to carry) out your responsibilities.
          </p>
          <p className="h6">
            Your validator has its own balance, with the initial balance
            outlined in the deposit contract. Your rewards and penalties are
            reflected in your validator's balance over time.
          </p>
          {/* border */}
          {/* <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            Since The Merge, validators will also be responsible for processing
            transactions, and thus be entitled to unburnt gas fees associated
            with included transactions when proposing blocks. These fees are
            accounted for on the execution layer, not the consensus layer, and
            thus require a traditional Ethereum address to be provided to your
            client.
          </p>
          {/*  */}
          {/* <a href="#" className="text-blue font-md mb-3">
            {" "}
            View the Staking Ckecklist{" "}
          </a>  */}

          <h6 className="h6 pt-4 rajdhani-700">
            How often are rewards/penalties issued?
          </h6>
          <p className="h6">
            Rewards and penalties are issued every 6.4 minutes—a period of time
            known as an epoch.
          </p>
          <p className="h6">
            Every epoch, the network measures the actions of each validator and
            issues your rewards or penalties appropriately.
          </p>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            Your validator will also receive unburnt gas fees when proposing
            blocks. Validators are chosen randomly by the protocol to propose
            blocks, and only one validator can propose a block for each
            12-second slot. There are 7200 slots each day, so each validator has
            7200 chances-per-day to propose a block. If there are 500,000
            validators, each validator will average a block proposal every 70
            days.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            How large are the rewards/penalties?
          </h6>
          <p className="h6">
            Rewards and penalties are issued every 6.4 minutes—a period of time
            known as an epoch.
          </p>
          <p className="h6">
            Every epoch, the network measures the actions of each validator and
            issues your rewards or penalties appropriately.
          </p>
          <p className="h6">
            Given a fixed total number of validators, the rewards/penalties
            predominantly scale with the balance of the validator—attesting with
            a higher balance results in larger rewards/penalties whereas
            attesting with a lower balance results in lower rewards/penalties.
          </p>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            Note however that this scaling mechanism works in a non-obvious way.
            To understand the precise details of how it works requires
            understanding a concept called effective balance.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            Why do rewards depend on the total number of validators in the
            network?
          </h6>
          <p className="h6">
            Block rewards are calculated using a sliding scale based on the
            total amount of NET1 staked on the network.
          </p>
          <p className="h6">
            In other words: if the total amount of NET1 staked is low, the
            reward (interest rate) is high, but as the total stake rises, the
            reward (interest) paid out to each validator starts to fall.
          </p>
          <p className="h6">
            Why a sliding scale? While we won’t get into the gory details here,
            the basic intuition is that there needs to be a minimum number of
            validators (and hence a minimum amount of NET1 staked) for the
            network to function properly. So, to incentivize more validators to
            join, it’s important that the interest rate remains high until this
            minimum number is reached.
          </p>
          <p className="h6">
            Afterwards, validators are still encouraged to join (the more
            validators the more decentralized the network), but it’s not
            absolutely essential that they do so (so the interest rate can
            fall).
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            How badly will I be penalized for being offline?
          </h6>
          <p className="h6">
            It depends. In addition to the impact of effective balance there are
            two important scenarios to be aware of:
          </p>
          <ul className="mx-3 d-flex">
            <li>
              <span className="h6 rajdhani-600 mx-2">1. </span>
            </li>
            <li>
              <p className="h6">
                Being offline while a supermajority (2/3) of validators is still
                online leads to relatively small penalties as there are still
                enough validators online for the chain to finalize.
                <span className="rajdhani-600">
                  This is the expected scenario.
                </span>{" "}
              </p>
            </li>
          </ul>
          <ul className="mx-3 d-flex">
            <li>
              <span className="h6 rajdhani-600 mx-2">2. </span>
            </li>
            <li>
              <p className="h6">
                Being offline at the same time as more than 1/3 of the total
                number of validators leads to harsher penalties, since blocks do
                not finalize anymore
                <span className="rajdhani-600">
                  {" "}
                  This scenario is very extreme and unlikely to happen.
                </span>
              </p>
            </li>
          </ul>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            Note that in the second (unlikely) scenario, you stand to
            progressively lose up to 50% (5000 NET1) of your stake over 21 days.
            After 21 days you are ejected out of the validator set. This ensures
            that blocks start finalizing again at some point.
          </p>
          {/*  */}
          <h6 className="h6 pt-4 rajdhani-700">
            How great does my uptime need to be for my validator to be net
            profitable?
          </h6>
          <p className="h6">
            Overall, we'd expect your validator to be net profitable as long as
            your uptime is greater than 50%.
          </p>
          <p className="h6">
            This means that you don't need to go to extreme lengths with backup
            clients or redundant internet connections as the repercussions of
            being offline are not so severe.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            How much will I be penalized for acting maliciously?
          </h6>
          <p className="h6">
            Again, it depends. Behaving maliciously—for example attesting to
            invalid or contradicting blocks—will lead to your stake being
            slashed.
          </p>
          <p className="h6">
            The minimum amount that can be slashed is 100 NET1, but
            <span className="rajdhani-600">
              this number increases if other validators are slashed at the same
              time.
            </span>
          </p>
          <p className="h6">
            The idea behind this is to minimize the losses from honest mistakes,
            but strongly disincentivize coordinated attacks.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">What exactly is slashing?</h6>
          <p className="h6">
            Slashing has two purposes: (1) to make it prohibitively expensive to
            attack the network, and (2) to stop validators from being lazy by
            checking that they actually perform their duties. If you're slashed
            because you've acted in a provably destructive manner, a portion of
            your stake will be destroyed.
          </p>
          <p className="h6">
            If you're slashed you're prevented from participating in the
            protocol further and are forcibly exited.
          </p>
          {/* Withdrawal credentials */}
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-4">
            <a href="#" className="text-white">
              Withdrawal credentials
            </a>
            <hr />
          </h2>
          <h6 className="h6 pt-4 rajdhani-700">
            What are withdrawal credentials?
          </h6>
          <p className="h6">
            Withdrawal credentials is a 32-byte field associated with every
            validator, initially set during deposit, for verifying the
            destination of valid withdrawals. Currently, there are two types of
            withdrawal credentials: BLS credentials (Type 0, or ) and execution
            (Ethereum address) credentials (Type 1, or )
          </p>
          {/*  */}
          <ul className="d-flex">
            <li>
              <p>
                <i
                  className="bi bi-circle-fill mx-3"
                  style={{ fontSize: "8px" }}
                />
              </p>
            </li>
            <li>
              <p className="h6">
                BLS credentials: By default, the deposit CLI would generate
                withdrawal credentials with the withdrawal key derived via
                mnemonics. This format is not compatible with Network1
                withdrawals and must be updated to the staking address
                credentials to enable withdrawals.
              </p>
            </li>
          </ul>
          <ul className="d-flex">
            <li>
              <p>
                <i
                  className="bi bi-circle-fill mx-3"
                  style={{ fontSize: "8px" }}
                />
              </p>
            </li>
            <li>
              <p className="h6">
                Execution credentials: If you want to withdraw to your execution
                wallet address you can set an “NET1 withdrawal address” using
                when running the deposit CLI. Please ensure that you have
                control over the keys to this address.
              </p>
            </li>
          </ul>
          {/*  */}
          <h6 className="h6 pt-4 rajdhani-700">
            Can I change the withdrawal credentials of my validator after the
            first deposit?
          </h6>
          <p className="h6">
            If the “NET1 withdrawal address” was not provided on initial
            deposit, you can submit a once-only BLS To Execution Change message
            signed with your BLS withdrawal keys to specify your desired
            Ethereum withdrawal address. This address can only be provided once,
            and cannot be changed again.
          </p>

          {/* key */}
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-4">
            <a href="#" className="text-white">
              Keys
            </a>
            <hr />
          </h2>
          <h6 className="h6 pt-4 rajdhani-700">
            What happens if I lose my signing key?
          </h6>
          <p className="h6">
            If you lose your signing key, your validator can no longer propose
            or attest.
          </p>
          <p className="h6">
            Over time, your balance will decrease as you are penalized for not
            participating in the consensus process. When your balance reaches
            5000 NET1, you will be automatically exited from the validator set.
          </p>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            However, all is not lost. Assuming you derive your keys using
            EIP2334 (as per the default onboarding flow) then you can always
            recalculate your signing key from your mnemonic.
            {/* <a href="#" className="text-blue">
              EIP2334{" "}
            </a>{" "}
            <i className="bi bi-arrow-up-right m-1" />
            (as per the default onboarding flow) then{" "}
            <span className="rajdhani-600">
              you can always recalculate your signing key from your mnemonic.
            </span> */}
          </p>
          <p className="h6">
            If you've already provided an Network1 withdrawal address for your
            withdrawal credentials, your entire remaining balance will be
            transferred to this address upon exit. If not, your mnemonic will
            still be needed to generate your withdrawal key to set your
            withdrawal address.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">
            What happens if I have BLS withdrawal credentials and I lose my
            withdrawal key?
          </h6>
          <p className="h6">
            If you lose your withdrawal key, your mnemonic will be needed to
            recover. If your mnemonic is lost, and you have not updated your
            withdrawal credentials with an Network1 (execution) withdrawal
            address, there is no way to access the funds held by your validator.
            As such, it is essential to ensure your validator mnemonic is safely
            backed up.
          </p>
          <p className="h6">
            Once an execution address has been provided by signing the BLS To
            Execution Change message, the withdrawal keys derived from your
            validator mnemonic are no longer utilized. Therefore, providing an
            execution address that you control as early as possible is the
            recommended option for most users, and is the default path for those
            who join via this site's onboarding process. This protects you
            against permanent loss of all funds in the event you are unable to
            recover your mnemonic and withdrawal keys.
          </p>
          {/* <a href="#" className="text-blue h6">
            More on withdrawals
          </a> */}

          <h6 className="h6 pt-4 rajdhani-700">
            What happens if my withdrawal key is stolen?
          </h6>
          <p className="h6">
          If you provided a withdrawal address when initially generating your keys, the withdrawal key no longer has any use. The only address that validator funds can be transferred to is this address, and it cannot be changed once set.
          </p>
          <p className="h6">
          If this was not provided, the withdrawal key can be used to sign a message declaring a withdrawal address. The withdrawal key can be generated using your mnemonic. If your withdrawal key or mnemonic have been stolen, and a withdrawal address has not yet been set, the thief will have the ability to irreversibly designate their own account as the withdrawal address for your validator.
          </p>
          <p
            className="h6 p-1 px-3"
            style={{ borderInlineStart: "4px solid #ffa31b" }}
          >
            If your mnemonic is stolen/compromised, and you have not yet set a withdrawal address, you should attempt this IMMEDIATELY to prevent permanent loss of funds, before the attacker irreversibly designates their own withdrawal address for your validator.
          </p>
          <p className="h6">
          If a withdrawal address has not been set, and you have lost access to the mnemonic seed phrase without it being stolen/compromised, your funds will remain locked in the validator account indefinitely.
          </p>

          <h6 className="h6 pt-4 rajdhani-700">Why two keys instead of one?</h6>
          <p className="h6">
          If a withdrawal address has not been set, and you have lost access to the mnemonic seed phrase without it being stolen/compromised, your funds will remain locked in the validator account indefinitely.
          </p>
          {/* support */}
          <h2 className="h4 text-white rajdhani-600 lh-1 m-0 pt-4">
            <a href="#" className="text-white">
              Support
            </a>
            <hr />
          </h2>
          <h6 className="h6 pt-4 rajdhani-700">
            Where can I find troubleshooting support?
          </h6>
          <p className="h6">
          If you have questions, the Network1 community is always there for you to get help! You can find support on
            <a href="#" className="text-blue">
             Telegram<i className="bi bi-arrow-up-right m-1" />
            </a>
            or 
            <a href="#" className="text-blue">
              Discord<i className="bi bi-arrow-up-right m-1" />
            </a>
            or 
            <a href="#" className="text-blue">
              Reddit. <i className="bi bi-arrow-up-right m-1" />
            </a>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
