import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UploadDeposit from './Components/Stake/UploadDeposit';
import SelectClient from './Components/Stake/SelectClient';
import Overview from './Components/Stake/Overview';
// import Consensusclient from './Components/Stake/Consensusclient';
import Connectwallet from './Components/Stake/Connectwallet';
import Generatekeys from './Components/Stake/Generatekeys';
import Transactions from './Components/Stake/Transactions';
import Checklist from './Components/Checklist/Checklist';
import Faq from './Components/Faq/Faq';
import Topup from './Components/Top-up/Topup';
import Withdrawals from './Components/Withdrawals/Withdrawals';
import Desktop from './Components/Stake/Desktop';
import PrivacyPolicy from './Components/Privacy Policy/PrivacyPolicy';


function App() {
  return (
  //  <Index/>
   <Routes>
     <Route path="/" element={<Index />} />
     <Route path="/overview" element={<Overview />} />
     <Route path="/select-client" element={<SelectClient />} />
     {/* <Route path="/consensus-client" element={<Consensusclient />} /> */}
     <Route path="/upload-deposit-data" element={<UploadDeposit />} />
     <Route path="/generate-key" element={<Generatekeys />} />
     <Route path="/connect-wallet" element={<Connectwallet />} />
     <Route path="/transactions" element={<Transactions />} />
     <Route path="/Checklist" element={<Checklist />} />
     <Route path="/faq" element={<Faq />} />
     <Route path="/top-up" element={<Topup />} />
     <Route path="/withdrawals" element={<Withdrawals />} />
     <Route path="/desktop" element={<Desktop />} />\
     <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
    
   </Routes>
  );
}

export default App;
