// import React from "react";

// export default function Footer() {
//   return (
//     <>
//       <div className="footer-wrapper pt-75 bg-darkblack dark-bg">
//         <div className="container-wide">
//           <div className="row pb-5 text-lg-start text-md-start  text-sm-center text-start text-center">

//             <div className="col-lg-3 col-md-6 col-sm-12 lg-mt-6 ms-auto d-flex justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
//               <div className="text-start">
// <h4 className="text-white h6 rajdhani-700 mb-4 mt-1 text-uppercase">
//   Staking Launchpad
// </h4>
//                 <ul className="list-group gap-2">
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Deposit
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Checklist
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       FAQ
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Terms of Service
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             <div className="col-lg-5   ">
//               <div className="fugu--btn-wrap  fugu--hero-btn text-center">
//                 <a
//                   className="fugu--btn bg-gradient"
//                   href=""
//                   style={{ color: "white" }}
//                 >
//                   Become a validator 🦏
//                 </a>
//               </div>
//               {/* <a href="#" className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase">Become a validator 🦏</a> */}
//             </div>
//             <div className="col-lg-3 col-md-6  lg-mt-6 col-sm-12 sm-mt-3 md-mt-3 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center">
//               <div className="text-start">
// <h4 className="text-white h6 rajdhani-700 mb-4 mt-1 text-uppercase ">
//   More on staking
// </h4>
//                 <ul className="list-group gap-2 ">
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       The Ethereum roadmap
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Avoid phishing
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Staking economics
//                     </a>
//                   </li>
//                   <li className="list-group-item bg-transparent border-0 p-0 ">
//                     <a href="#" className="rajdhani-600 text-gray-100 font-sm">
//                       Formal verification report
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             {/* <div className="col-12 border-dark border-top mt-50">
//                             <p className="py-4 text-center rajdhani-600 text-gray-100 font-sm mb-0">© 2022 starapp copyright. All images are for demo purposes.</p>
//                         </div> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React from "react";

export default function Footer() {
  return (
    <>
      <footer
        className="fugu-footer-section"
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row justify-content-between align-content-center">
            <div className=" col-md-4 col-sm-4">
              <div className="fugu-footer-menu">
                <div className="fugu-textarea">
                  <a href="/">
                    <div className="fugu-footer-logo">
                      <img
                        src="assets/images/img-one/N1_logo_light.png"
                        style={{ width: "220px" }}
                        alt=""
                        className="light-version-logo"
                      />
                    </div>
                  </a>
                </div>
                <ul>
                  <p className="text-white">
                    Embark on your crypto-networking journey with ease and
                    security. Join our platform for Crypto, Forex, RWA, Dex,
                    Social Media, Browser, All in one place.
                  </p>
                  <li>
                    <a href="mailto:info@network1.tech">
                    info@network1.tech
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4  col-md-4 col-sm-4">
              {/* <h4 className="text-white h6 rajdhani-700 mb-4 mt-1 text-uppercase">
                Staking Launchpad
              </h4> */}
              <div className="fugu-footer-menu">
                <ul>
                  <li>
                    <a href="/overview" className="">
                      Deposit
                    </a>
                  </li>
                  <li>
                    <a href="/checklist" className="">
                      Checklist
                    </a>
                  </li>
                  <li>
                    <a href="/faq" className="">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/top-up" className="">
                      Top-Up
                    </a>
                  </li>
                  <li>
                    <a href="/withdrawals" className="">
                      Withdrawals
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className="">
                    Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 ">
              <div className="fugu-footer-menu fugu-custom-margin">
                <h4 className="text-white h6 rajdhani-700 mb-4 mt-1 text-uppercase ">
                  QUICK LINKS
                </h4>
                <ul>
                  <li>
                    <a
                      href="https://networkchain.gitbook.io/networkchain/proof-of-network-pon"
                      className=""
                    >
                      Proof-of-Network (PoN) Consensus
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://networkchain.gitbook.io/networkchain/intro-network-chain"
                      className=""
                    >
                      Introduction to Network1
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://networkchain.gitbook.io/networkchain/ecosystem"
                      className=""
                    >
                      Network1 Ecosystem
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className=" col-md-4 col-sm-4">
              <div className="fugu-footer-menu fugu-custom-margin">
              <div className="fugu--btn-wrap  fugu--hero-btn text-center">
                <a
                  className="fugu--btn bg-gradient"
                  href=""
                  style={{ color: "white" }}
                >
                  Become a validator 🦏
                </a>
              </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
