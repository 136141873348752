import React, { useContext, useEffect } from "react";
import Header from "../Comman/Header";
import Navbar from "./Navbar";
import Footer from "../Comman/Footer";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import { appContext } from "../../AppContext";

export default function Transactions() {
  const { validteReload } = useContext(appContext);
  useEffect(() => {
    validteReload();
  });

  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{
          backgroundImage: "url(assets/images/img-one/n1_network-bg.png)",
        }}
        // style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}
      >
        <Header />
        <Navbar />
        <div className="container mt-5 mb-5">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0 mb-1">
            Transaction
          </h2>
          <br />
          <div className="row justify-content-center">
            <div className="col-10">
              <div className=" rounded-10 bg-black p-4 mt-3">
                <div class="row ">
                  <div className="col-12 ">
                    <ul className="d-flex justify-content-between">
                      <li>
                        <h2 className="h4 text-gray-900 dark-text rajdhani-600 lh-1 mt-4 mb-1">
                          Tnx Hash
                        </h2>
                      </li>
                    </ul>
                    <hr />
                  </div>
                </div>
                <p
                  className="h6 pt-4 text-center"
                  onClick={() =>
                    copy("0xd536597060f72f8cCE09D873F07c9520aF546C4E")
                  }
                >
                  0xd536597060f72f8cCE09D873F07c9520aF546C4E
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-copy"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                    />
                  </svg>
                </p>
                <div
                  className="rounded-6 mt-5 p-3 contact-form style1"
                  style={{
                    backgroundColor: "rgba(255, 222, 179, 0.18)",
                    border: "1px solid burlywood",
                  }}
                >
                  <div className="col-12">
                    <p className="h6">
                      A withdrawal address has not been set for these
                      validators. Staked funds and rewards will remain locked
                      until withdrawal credentials are provided.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div class="col-md-10">
                <div className="row justify-content-center mt-4">
                  <div class="col-md-3">
                    <Link
                      to="/upload-deposit-data"
                      className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                      // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                    >
                      Back
                    </Link>
                  </div>
                  <div class="col-md-3">
                    <Link
                    className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                    //  className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                     >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
