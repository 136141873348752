import React from "react";

export default function Scaling() {
  return (
    <>
      <div className="faq-wrapper pb-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-1">
              <h2
                className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1 "
                style={{ color: "black" }}
              >
                How is Network1 scaling?
              </h2>
            </div>
            <div className="col-12 page-nav d-flex mb-5 pt-3">
              <p className="h6" style={{ color: "black" }}>
                Several upgrades are underway that will make Network1 more
                scalable, secure, and sustainable. These upgrades will improve
                Network1 while seamlessly continuing on the chain of today.
                Here's more on the different upgrades:
              </p>
            </div>
            <div className="col-12">
              <h3 className="fw-bold" style={{ color: "black" }}>
                Proof-of-stake and the Network1 Chain
              </h3>
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  Network1 is secured by proof-of-stake, although this was not
                  always the case. The Network1 Chain was the first step to
                  establishing a PoS consensus layer on Network1, launching in
                  parallel to Mainnet in December 2020. This allowed
                  participants to start staking their NET1 and prepare the
                  network for the formal transition from proof-of-work to
                  proof-of-stake via The Merge.
                </p>
              </div>
              <a href=""
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on the Network1 Chain
                <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" />
              </a>
              
              <h3 className="fw-bold pt-3" style={{ color: "black" }}>
                The Merge
              </h3>

              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  The Merge upgrade officially brought proof-of-stake to
                  Network1 on September 15, 2022, simultaneously deprecating
                  proof-of-work. This prepared the chain for future scaling
                  upgrades such as Danksharding by bringing proof-of-stake
                  consensus together with Mainnet, while simultaneously reducing
                  energy consumption by over 99.9%.
                </p>
              </div>
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  This marked the merging of the execution layer (existing
                  Mainnet) with the new consensus layer (the Network1 Chain) to
                  form the single Network1 chain of today.
                </p>
              </div>

              <a href=""
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on the Merge
                {/* <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" /> */}
              </a>
              <br />
              <br/>
              <h3 className="fw-bold" style={{ color: "black" }}>
                Sharding
              </h3>
              <div className="accordion-body ps-0">
                <p className="lh-26 h6 text-gray-800 dark-text ">
                  Danksharding will change the requirement that full nodes carry
                  the entire history of the chain, and instead will distribute
                  this load amongst the network while still ensuring data
                  availability. This will significantly expand the capacity of
                  layer 1
                </p>
              </div>

              <a href=""
                className="h6 my-0 lh-36 rajdhani-600  dark-text"
                style={{ color: "black" }}
              >
                More on Danksharding
                {/* <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h6" /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
