import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import Navbar from "./Navbar";
import ContractAbi from "../Comman/contractabi.json";
import { appContext } from "../../AppContext";
import { Link, useNavigate } from "react-router-dom";

export default function UploadDeposit() {
  const { file, setFile, validteReload } = useContext(appContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleFileChange = (event, dragfile) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        try {
          if (fileData) {
            const data = JSON.parse(fileData);
            if (Array.isArray(data)) {
              if (
                data[0]?.pubkey &&
                data[0]?.withdrawal_credentials &&
                data[0]?.amount &&
                data[0]?.signature &&
                data[0]?.deposit_message_root &&
                data[0]?.deposit_data_root &&
                data[0]?.fork_version &&
                data[0]?.network_name &&
                data[0]?.deposit_cli_version
              ) {
                setFile(JSON.parse(fileData)[0]);
                setError("");
                setSuccess(selectedFile.name);
              } else {
                setError(`${selectedFile.name} isn't a valid deposit_data JSON file. Try again.`);
              }
            } else {
              setError("keystore-m_12381_3600_0_0_0-1709017990.json isn't a valid deposit_data JSON file. Try again.");
            }
          }
        } catch (err) {
          console.log("error : ", err);
          setError("Invalid file.");
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  useEffect(() => {
    validteReload();
  });

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const selectedFile = droppedFiles[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        try {
          if (fileData) {
            const data = JSON.parse(fileData);
            if (Array.isArray(data)) {
              if (
                data[0]?.pubkey &&
                data[0]?.withdrawal_credentials &&
                data[0]?.amount &&
                data[0]?.signature &&
                data[0]?.deposit_message_root &&
                data[0]?.deposit_data_root &&
                data[0]?.fork_version &&
                data[0]?.network_name &&
                data[0]?.deposit_cli_version
              ) {
                setFile(JSON.parse(fileData)[0]);
                setError("");
                setSuccess(selectedFile.name);
              } else {
                setError(`${selectedFile.name} isn't a valid deposit_data JSON file. Try again.`);
              }
            } else {
              setError("keystore-m_12381_3600_0_0_0-1709017990.json isn't a valid deposit_data JSON file. Try again.");
            }
          }
        } catch (err) {
          console.log("error : ", err);
          setError("Invalid file.");
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  return (
    <>
      <div
        className="main-wrapper dark-bg3"
        style={{ backgroundImage: "url(assets/images/img-one/n1_network-bg.png)" }}
        // style={{ background: "linear-gradient(90deg, hsl(29deg 92% 70% / 46%) 0%, hsl(0deg 87% 73% / 57%) 100%)" }}
      >
        <Header />
        <Navbar />
        <div className="container mt-5 mb-5">
          <h2 className="h1 text-white rajdhani-600 lh-1 m-0">Upload deposit data</h2><br/>
          <div className="row pt-2 pb-5">
            <div className="col-12">
              <div className="subscribe-banner rounded-10 bg-black">
                <div className="row justify-content-center">
                  <div class="col-md-8">
                    <div class="mb-3 form-group">
                      <label class="form-label font-md rajdhani-700 text-gray-900 dark-text lh-18">
                        Upload files
                        <br/><span class="font-xss rajdhani-500 text-gray-500">
                          Upload the deposit data file you just generated. The deposit_data-[timestamp].json is located
                          in your /staking-deposit-cli/validator_keys directory.
                        </span>
                      </label>
                      {error ? (
                        <div class="mt-3 form-group">
                          <label class="rounded-6 text-center  btn-tertiary js-labelFile w-100 border-0 light-bg py-5 cursor-auto">
                            <div className="mb-3">
                              <svg
                                aria-label="DocumentMissing"
                                viewBox="0 0 24 24"
                                height="50px"
                                y="15px"
                                class="StyledIcon-ofa7kd-0 jZnYpm"
                              >
                                <path
                                  fill="none"
                                  stroke="#000"
                                  stroke-width="2"
                                  d="M2.99787498,0.999999992 L17.4999998,0.999999992 L20.9999998,4.50000005 L21,23 L3,23 L2.99787498,0.999999992 Z M16,1 L16,6 L21,6 M9,12 L15,18 M15,12 L9,18"
                                ></path>
                              </svg>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                src="/assets/images/cross-icon.svg"
                                height={12}
                                className="mx-2 cursor-pointer"
                                onClick={() => {
                                  setError("");
                                  setFile(null);
                                  setSuccess("");
                                }}
                              />
                              <span class="js-fileName text-gray-600 font-xss rajdhani-600 mt-0">{error}</span>
                            </div>
                          </label>
                        </div>
                      ) : success ? (
                        <div class="mt-3 form-group">
                          <label class="rounded-6 text-center bg-gray-100 btn-tertiary js-labelFile w-100 border-0 light-bg py-5 cursor-auto">
                            <div className="mb-3">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 130.2 130.2"
                                height="50px"
                              >
                                <circle
                                  class="path circle"
                                  fill="#D0E1D5"
                                  stroke="#D0E1D5"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  cx="65.1"
                                  cy="65.1"
                                  r="62.1"
                                ></circle>
                                <polyline
                                  class="path check"
                                  fill="#D0E1D5"
                                  stroke="#fff"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                ></polyline>
                              </svg>
                            </div>
                            <div className="d-flex justify-content-center align-items-center cursor-pointer">
                              <img
                                src="/assets/images/cross-icon.svg"
                                height={12}
                                className="mx-2"
                                onClick={() => {
                                  setError("");
                                  setFile(null);
                                  setSuccess("");
                                }}
                              />
                              <span class="js-fileName text-gray-600 font-xss rajdhani-600 mt-0">{success}</span>
                            </div>
                          </label>
                        </div>
                      ) : (
                        <div class="mt-3 form-group" onDragOver={handleDragOver} onDrop={handleDrop}>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            class="input-file"
                            onChange={(e) => handleFileChange(e)}
                          />
                          <label
                            for="file"
                            class="rounded-6 text-center btn-tertiary js-labelFile w-50 border-0 light-bg py-5"
                          >
                            <i class="bi bi-cloud-arrow-up-fill large-icon text-gray-900 dark-text"></i>
                            <span class="js-fileName text-gray-600 font-xss rajdhani-600 mt-0">
                              Drag and drop or click to replace
                            </span>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div class="col-md-10">
                <div className="row justify-content-center">
                  <div class="col-md-3">
                    <Link
                      to="/generate-key"
                      className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                      // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-gray px-5 py-3 font-sm mt-3"
                    >
                      {" "}
                      Back
                    </Link>
                  </div>
                  <div class="col-md-3">
                    <button
                    className="fugu--btn bg-gradient bg-current px-5 py-3 font-sm mt-3" style={{color:"white"}}
                      // className="btn btn-lg btn-block text-gray-900 rajdhani-700 rounded-10 bg-current px-5 py-3 font-sm mt-3"
                      onClick={() => navigate("/connect-wallet")}
                      disabled={ file && !error ? false :  true}
                    >
                      {" "}
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
