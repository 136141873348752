import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  return (
    <>
      <header
        className="header-wrapper w-100 header-center dark-bg "
        style={{ backgroundColor: "black" }}
      >
        <nav className="navbar navbar-expand-lg navbar-light border-0">
          <div className="container-wide posr">
            <div className="col-lg-2 col-6">
              <a href="https://network1.tech/">
                <img
                  src="assets/images/img-one/N1_logo_light.png"
                  alt=""
                  className="light-version-logo"
                  style={{ width: "220px" }}
                />
              </a>
            </div>
            <div className="col-lg-8 d-none d-lg-block">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul
                  className="navbar-nav mb-2 mb-lg-0 ms-lg-4"
                  id="navbar-main"
                >
                  <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                    {/* <a className="nav-link ps-0 text-white  text-uppercase" href="/overview"> Deposit</a> */}
                    <a
                      className={`nav-link   text-white ps-0 d-none d-xl-block  text-uppercase ms-3 ${
                        location.pathname === "/overview" ? "activ-2 " : ""
                      }`}
                      href="/overview"
                    >
                      Deposit
                    </a>

                    {/* <ul className="megamenu">
                                            <li className="menu-item">
                                                <span className="rajdhani-500 text-gray-500">
                                                    <link rel="stylesheet" to="" /> Deposit</span>
                                                <ul className="sub-mega-menu">
                                                    <li><a href="home-1.html">Home One</a></li>
                                                    <li><a href="home-2.html">Home Two</a></li>
                                                    <li><a href="home-3.html">Home Three</a></li>
                                                    <li><a href="home-4.html">Home Four</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item">
                                                <span className="rajdhani-500 text-gray-500">Demo Two</span>
                                                <ul className="sub-mega-menu">
                                                    <li><a href="home-5.html">Home Five </a></li>
                                                    <li><a href="home-6.html">Home Six</a></li>
                                                    <li><a href="home-7.html">Home Seven</a></li>
                                                    <li><a href="home-8.html">Home Eight</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item">
                                                <span className="rajdhani-500 text-gray-500">Demo Three</span>
                                                <ul className="sub-mega-menu">
                                                    <li><a href="home-9.html">Home Nine</a></li>
                                                    <li><a href="home-10.html">Home Ten</a></li>
                                                    <li><a href="home-11.html">Home Eleven</a></li>
                                                    <li><a href="home-12.html">Home Twelve</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item">
                                                <span className="rajdhani-500 text-gray-500">Demo Four</span>
                                                <ul className="sub-mega-menu">
                                                    <li><a href="home-13.html">Home Thirteen</a></li>
                                                    <li><a href="home-14.html">Home Fourteen</a></li>
                                                    <li><a href="home-15.html">Home Fifteen</a></li>
                                                    <li><a href="coming-soon.html">Home Sixteen</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                  </li>

                  <li className="nav-item menu-item-has-children  menu-item-has-megamenu">
                    {/* <a className="nav-link  text-uppercase text-white " href="/checklist">Checklist</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/checklist" ? "activ-2 " : ""
                      }`}
                      href="/checklist"
                    >
                      Checklist
                    </a>
                  </li>
                  <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                    {/* <a className="nav-link text-white text-uppercase" href="/faq">FAQ</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/faq" ? "activ-2 " : ""
                      }`}
                      href="/faq"
                    >
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item menu-item-has-children menu-item-has-megamenu ">
                    {/* <a className="nav-link text-white text-uppercase " href="/top-up">Top-Up</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/top-up" ? "activ-2 " : ""
                      }`}
                      href="/top-up"
                    >
                      Top-Up
                    </a>
                  </li>
                  <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                    {/* <a className="nav-link text-white text-uppercase" href="/withdrawals">Withdrawals</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/withdrawals" ? "activ-2 " : ""
                      }`}
                      href="/withdrawals"
                    >
                      Withdrawals
                    </a>
                  </li>
                </ul>
                <a
                  href="#"
                  className="btn btn-md menu-btn text-gray-800 rajdhani-700 rounded-6 bg-transparent border-dark border-2 px-4 font-sm text-uppercase dark-text"
                  data-bs-toggle="modal"
                  data-bs-target="#connetwallet"
                >
                  {" "}
                  Connect Wallet
                </a>
                <a
                  href="dashboard-history.html"
                  className="ms-2 btn menu-btn btn-noti"
                >
                  <i className="bi-bell font-md m-0" />
                  <span className="dot-count bg-current rajdhani-600">6</span>
                </a>
                <a
                  href="#"
                  data-switch-theme
                  className="ms-2 menu-btn btn-toggle-dark btn btn-icon btn-round"
                >
                  <i className="bi-brightness-low font-xl m-0" />
                </a>
              </div>
            </div>
            <div className="d-flex justify-content-end d-block d-lg-none">
              <div className="d-block d-lg-none ">
                {/* <ul className=" mb-2 mb-lg-0 ms-lg-4">
                  <li className=" menu-item-has-children">
                    <a
                      className="nav-link text-white  text-uppercase mega-menu btn  btn-md  me-1 mt-1 header-btn text-gray-800 rajdhani-700  bg-current px-4 font-sm"
                      href="#"
                    >
                      Mainnet
                    </a>
                    <ul className="sub-menu-1 sub-menu">
                      <li className="menu-item">
                        <a href="/">
                          Switch to Holesky testnet launchpad <i className="bi bi-arrow-up-right m-1" />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
                {/* <a href="#" id="connetw"   className="btn  btn-md me-md-5 me-1 mt-1 header-btn text-gray-800 rajdhani-700 rounded-pill bg-current px-4 font-sm  text-uppercase" data-bs-toggle="modal" data-bs-target="#connetwallet"> Connect Wallet</a> */}
                {/* <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button> */}
              </div>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="col-lg-12 d-lg-none w-100">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0" id="navbar-main">
                  <li className="nav-item menu-item-has-children">
                    {/* <a className="nav-link text-white mega-menu text-uppercase" href="/overview">Deposit</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase d-none ${
                        location.pathname === "/overview" ? "activ-2 " : ""
                      }`}
                      href="/overview"
                    >
                      Deposit
                    </a>
                  </li>
                  <li className="nav-item menu-item-has-children">
                    {/* <a className="nav-link text-white mega-menu text-uppercase" href="/checklist">Checklist</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/checklist" ? "activ-2 " : ""
                      }`}
                      href="/checklist"
                    >
                      Checklis
                    </a>

                    {/* <ul className="sub-menu">
                                            <li className="menu-item"><a href="blog.html">Blog</a></li>
                                            <li className="menu-item"><a href="blog-classic.html">Blog Classic</a></li>
                                            <li className="menu-item"><a href="single-blog-1.html">Single Blog 1</a></li>
                                            <li className="menu-item"><a href="single-blog-2.html">Single Blog 2</a></li>
                                        </ul> */}
                  </li>
                  <li className="nav-item menu-item-has-children">
                    {/* <a className="nav-link text-white mega-menu text-uppercase" href="/faq">FAQ</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/faq" ? "activ-2 " : ""
                      }`}
                      href="/faq"
                    >
                      FAQ
                    </a>

                    {/*  */}
                  </li>
                  <li className="nav-item menu-item-has-children">
                    {/* <a className="nav-link text-white mega-menu text-uppercase" href="/top-up">Top Up</a> */}
                    <a
                      className={`nav-link   text-white ps-0  text-uppercase  ${
                        location.pathname === "/top-up" ? "activ-2 " : ""
                      }`}
                      href="/top-up"
                    >
                      Top-Up
                    </a>

                    {/*  */}
                  </li>
                  {/* <li className="nav-item"><a className="nav-link text-white text-uppercase" href="/withdrawals">Withdrawals</a></li> */}
                  <a
                    className={`nav-link   text-white ps-0  text-uppercase  ${
                      location.pathname === "/withdrawals" ? "activ-2 " : ""
                    }`}
                    href="/withdrawals"
                  >
                    Withdrawals
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
