import React, { Profiler, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";

// 1. Get projectId
const projectId = process.env.REACT_APP_PROJECTID;

// 2. Set chains
const mainnet = {
  chainId: 17000,
  name: "Holesky Test Network",
  currency: "ETH",
  explorerUrl: "https://holesky.etherscan.io/",
  rpcUrl: "https://ethereum-holesky.publicnode.com/",
};

// 3. Create modal
const metadata = {
  name: "Onmax Launchpad",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  defaultChain: 17000,
});

export const appContext = createContext();

const AppContext = ({ children }) => {
  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [show, setshow] = useState([
    { checked: false, tab: true },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
    { checked: false, tab: false },
  ]);

  const [provider, setProvider] = useState(null);
  const [balance, setBalance] = useState(0);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [validators, setValidators] = useState(1);
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [securityCheck, setSecurityCheck] = useState(false);

  useEffect(() => {
    if (address) {
      setConnectedAddress(address);
    } else {
      setConnectedAddress("");
    }
  }, [address]);

  const setEther5Provider = async () => {
    if (connectedAddress) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      setProvider(ethersProvider);
      const signer = ethersProvider.getSigner();
    } else {
      setProvider(null);
    }
  };

  useEffect(() => {
    setEther5Provider();
  }, [connectedAddress]);

  const getBalance = async () => {
    if (connectedAddress) {
      let balwei = await provider.getBalance(connectedAddress);
      let bal = ethers.utils.formatEther(balwei);
      setBalance(bal);
    } else {
      setBalance(0);
    }
  };

  useEffect(() => {
    if (provider) {
      getBalance();
    }
  }, [provider, chainId]);

  const handleTabs = (ind, val) => {
    const arr = [...show];
    arr[ind].checked = val;
    if (ind < 8) {
      arr[ind].tab = false;
      arr[ind + 1].tab = true;
    } else {
      navigate("/select-client");
    }
    setshow([...arr]);
  };

  const handleTabShow = (ind, val) => {
    const arr = [...show];
    for (let i = 0; i < arr.length; i++) {
      arr[i].tab = false;
    }
    if (arr[ind].checked === true) {
      arr[ind].tab = val;
      setshow([...arr]);
    }
  };

  const formatAddress = (text) => {
    if (text) {
      return text.substr(0, 6) + "..." + text.substr(-6, 6);
    } else {
      return "";
    }
  };

  const validteReload = () => {
    let temp = true;
    for (let i = 0; i < show.length; i++) {
      if (show[i].checked === false) {
        temp = false;
      }
    }
    if (!temp) {
      navigate("/");
    }
  };

  return (
    <appContext.Provider
      value={{
        show,
        setshow,
        handleTabShow,
        handleTabs,
        file,
        setFile,
        open,
        validators,
        setValidators,
        withdrawalAddress,
        setWithdrawalAddress,
        securityCheck,
        setSecurityCheck,
        connectedAddress,
        setConnectedAddress,
        chainId,
        isConnected,
        formatAddress,
        balance,
        fileData,
        setFileData,
        validteReload,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppContext;
